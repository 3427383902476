import React, { useEffect, useState, useRef } from "react";
import CryptoJS from "crypto-js";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Toast from "../../utils/Toast";
import { HttpMainApi, LoginProcParam } from "../../interface/main-api";
import {
  HttpAdminMainApi,
} from "../../interface/admin-main-api";
import { appConfig } from "../../config/Config";
import * as AuthMain from "../../auth/AuthMain";

import "./Login.css";

interface propsType {}

const Login = (props: propsType) => {
  const mainApi = new HttpMainApi();
  const adminApi = new HttpAdminMainApi();

  const [userId, setUserId] = useState("");
  const [userPwd, setUserPwd] = useState("");
  const [rememberId, setRememderId] = useState(false);
  const [checkRememberId, setCheckRememberId] = useState(false);
  const [needLogin, setNeedLogin] = useState(true);
  const pwdRef: any = useRef();
  const toastRef: any = useRef();

  const handlePasswordEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") fncProcLogin();
  };

  // 로그인 처리
  const fncProcLogin = async () => {
    if (userId === "" || userId.trim() === "") {
      toastRef.current?.toast("아이디를 입력하세요.", "warning", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (userPwd === "" || userPwd.trim() === "") {
      toastRef.current?.toast("패스워드를 입력하세요.", "warning", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    const param: LoginProcParam = {
      id: userId,
      password: userPwd,
    };

    const res = await mainApi.login_proc(param);
    if ("" + res.code === "200") {
      if (res.response.result === "success") procSesson(res.response.session);
      else procFail(res.response);
    } else {
      toastRef.current?.toast(res.response.error_msg, "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  // 로그인 성공
  const procSesson = (sessionData: any) => {
    toastRef.current?.toast("로그인 성공", "info", 3000, {
      vertical: "top",
      horizontal: "center",
    });
    AuthMain.setSession(sessionData);
    // 아이디 저장 기억하기
    const rememberIdObj: any = {
      id: sessionData.id,
      remember: "TRUE",
    };
    const objRememberId = JSON.stringify(rememberIdObj);
    if (rememberId) {
      window.localStorage.setItem(appConfig.rememberidSSName, objRememberId);
    }
    window.location.href = "/agree";
  };

  // 로그인 실패
  const procFail = (res: any) => {
    toastRef.current?.toast(res.result_msg, "error", 3000, {
      vertical: "top",
      horizontal: "center",
    });
  };

  // url 가져오기
  const stateInfo: URLSearchParams = new URLSearchParams(
    window.location.search
  );

  const getUserKey = (encryptedKey: string): string => {
    const before = "1928374651973";
    const after = "1428385651973";
    const result = encryptedKey.replace(before, "").replace(after, "");
    return result;
  };

  // rds 통신해서 람다 호출
  const check_user_rds = async (
    key_id: string,
    request_key: string,
    time_data: string
  ) => {
    const res = await adminApi.check_user_rds(key_id);
    if ("" + res.code == "200") {
      // 여기서 가져온 데이터를 바탕으로 확인을 진행함
      // console.log("check_user_rds result : " + JSON.stringify(res));
      if (res.response.result === "success") {
        const companies_info: string = res.response.session.user_info1;
        // console.log("response : " + JSON.stringify(res));

        if (window.location.hostname === "localhost") {
          // console.log("localhost test");
          appConfig.companiesDev.map((val) => {
            if (val.name === companies_info) {
              window.location.href = `${val.url}/login?requestKey=${request_key}&time=${time_data}`;
            }
          });
        }

        // user_info1 이 현대백화점이면 예외처리 해주도록 해야 함
        if (companies_info === appConfig.defaultCompany.name) {
          procSesson(res.response.session);
          // 백화점 서브도메인으로 이동하기
          window.location.href = `${appConfig.defaultCompany.url}/login?requestKey=${request_key}&time=${time_data}`;
        } else {
          // console.log("prod test");
          appConfig.companiesProd.map((val) => {
            if (val.name === companies_info) {
              window.location.href = `${val.url}/login?requestKey=${request_key}&time=${time_data}`;
            }
          });
        }
      }
    }
  };

  useEffect(() => {
    // none querystring
    window.history.replaceState({}, "", window.location.pathname);

    // 저장을 했는지 확인하고 저장했다면 skip
    const sessionData = AuthMain.getSession();

    if (sessionData !== null && sessionData.id !== null) {
      window.location.href = "/agree";
    }

    // requestKey
    if (stateInfo.get("requestKey") && stateInfo.get("time")) {
      const keyInfo: string = stateInfo.get("requestKey") ?? "";
      // console.log("keyInfo: ", keyInfo);
      const time: string = stateInfo.get("time") ?? "";
      const customKey = getUserKey(keyInfo);
      // console.log("customKey: ", customKey);
      let date = new Date();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let days = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let today = `${date.getFullYear()}${month}${days}` + "";
      if (time === today) {
        check_user_rds(customKey, keyInfo, time);
      } else {
        //날짜 확인후 주석
        // alert("아이디와 비밀번호로 로그인 진행해주세요");
      }
    } else {
      // 일반적인 로그인 진행
    }
  }, []);

  useEffect(() => {
    if (!rememberId && checkRememberId) {
      window.localStorage.removeItem(appConfig.rememberidSSName);
    }
  }, [rememberId]);

  return (
    <>
      {needLogin ? (
        <>
        {/* <div className="login-root">
          <div className="login-wrapper">
          <div className="login-box">
              <Stack spacing={2} direction="column">
                <h4>
                  <img
                    src="/images/login-logo.png"
                    className="login-logo"
                    alt="logo"
                  />
                </h4>
                <Typography>
                  AI 카피라이터 {appConfig.systemName} 어드민 입니다.
                </Typography>
                <div className="TextField-without-border-radius">
                  <TextField
                    id="txtUserId"
                    value={userId}
                    placeholder="아이디를 입력해주세요."
                    variant="outlined"
                    autoFocus
                    fullWidth
                    autoComplete="on"
                    sx={{ maxWidth: "400px" }}
                    inputProps={{ style: { padding: "12px 16px 10px 16px" } }}
                    onChange={(e) => {
                      setUserId(e.target.value);
                    }}
                  />
                </div>
                <div className="TextField-without-border-radius">
                  <TextField
                    inputRef={pwdRef}
                    value={userPwd}
                    placeholder="패스워드를 입력해주세요."
                    variant="outlined"
                    type="password"
                    autoComplete="current-password"
                    fullWidth
                    sx={{ maxWidth: "400px" }}
                    inputProps={{ style: { padding: "12px 16px 10px 16px" } }}
                    onChange={(e) => {
                      setUserPwd(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      handlePasswordEnter(e);
                    }}
                  />
                </div>
                <div>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      maxWidth: "400px",
                      borderRadius: "25px",
                      height: "44px",
                      mt: 3,
                    }}
                    color="primary"
                    onClick={() => {
                      fncProcLogin();
                    }}
                  >
                    로그인
                  </Button>
                </div>
              </Stack>
            </div>
            <div className="copy-warapper">
              <p className="copyright">© Hyundai Department Store. All rights reserved.</p>
            </div>
          </div>
          <Toast ref={toastRef} />
        </div> */}
      <div className="login-root">
        <div className="wrapper lewis login">
          <div className="login-box">
              <h2>
                <img src="/images/login-gling-logo.png" />
              </h2>
              <p>AI 카피라이터 {appConfig.systemName}에 오신걸 환영해요.</p>
              <div className="form-wrap">
                {" "}
                H-art를 통해서 접속해주시기 바랍니다.
              </div>
          </div>
          <div className="copy-warapper">
            <p className="copyright">© HYUNDAI GREEN FOOD. All rights reserved.</p>
          </div>
          <Toast ref={toastRef} />
        </div>
      </div>
      </>
      ) : (
        <></>
      )}
      
    </>
  );
};

export default Login;
