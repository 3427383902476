import axios from "axios";
import * as AuthMain from "../auth/AuthMain";
export abstract class ApiBase {
  protected apiName: string;
  protected urlPath: string;

  abstract onApiName(): string;
  abstract onUrlPath(): string;

  constructor() {
    this.apiName = this.onApiName();
    this.urlPath = this.onUrlPath();
  }

  protected async requestPost(requestBody: any): Promise<any> {
    try {
      const sessionCheck = AuthMain.getSession();
      let header: any = { timeout: 60000 };

      const response = await axios.post(this.urlPath, requestBody, header);
      return response.data;
    } catch (error) {
      return { code: "500", response: { error_msg: "apiTimeout" } };
    }
  }
}
