import {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useRef,
} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from "swiper";
import "swiper/swiper-bundle.css";

import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

import { HttpMainApi, GetHistoryInfoParam } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";

import Copy from "../reaction/Copy";
import Like from "../reaction/Like";
import UnLike from "../reaction/UnLike";

import "./CopyWriter.css";

SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);

interface propsType {
  userState: userState;
  pk: string;
  range: string;
}

const mainApi = new HttpMainApi();

const HistoryResult = (props: propsType, ref: any) => {
  const swiperRef = useRef<any>(null);

  const [historyInfo, setHistoryInfo] = useState<any>([]);

  const [copyContent, setCopyContent] = useState("문장 복사");
  const [copyTitle, setCopyTitle] = useState("제목 복사");

  const get_history_info = async () => {
    const param: GetHistoryInfoParam = {
      pk: props.pk,
      range: props.range,
    };
    const res = await mainApi.get_history_info(param);
    // console.log("res >> : ", res.response.data_info);
    if ("" + res.code === "200") {
      setHistoryInfo(res.response.data_info);
    } else {
      console.error(
        "get_product_history_info ERROR : ",
        res.response.error_msg
      );
    }
  };

  const titleCopy = useCallback(async (text: string) => {
    try {
      setCopyTitle("복사 완료");
      setTimeout(() => {
        setCopyTitle("제목 복사");
      }, 2000);
      await navigator.clipboard.writeText(text);
    } catch (error) {}
  }, []);

  useEffect(() => {
    get_history_info();
  }, []); //[] 호출시마다 조회

  return (
    <div>
      {historyInfo.map((item: any, index: number) => (
        <div className="result-wrapper" key={index}>
          <Stack direction="column" spacing={2.5} sx={{ pt: 0 }}>
            <div className="swiper mySwiper result-list history">
              <div>
                <Swiper
                  ref={swiperRef}
                  id="swiper"
                  tag="section"
                  loop={false}
                  navigation={true}
                  pagination={{ type: "fraction" }}
                  slidesPerView={1}
                  onSlideChange={(swiper) => {}}
                >
                  {item[2].map((contentList: any, index: number) => (
                    <SwiperSlide key={index + "" + contentList.content_no}>
                      <div className="swiper-slide">
                        <div className="slide-cont">
                          <div
                            className={
                              item[0].input_list.object === "1"
                                ? "type-notice-history bg-orange"
                                : "type-notice-history bg-green"
                            }
                          >
                            {item[0].input_list.object === "1"
                              ? "카피A"
                              : "카피B"}
                          </div>
                          <span className="time">좋아요 {item[0].like} 개</span>
                          <dl>
                            <dt>
                              <Tooltip
                                title={copyTitle}
                                placement="top"
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: "common.black",
                                      "& .MuiTooltip-arrow": {
                                        color: "common.black",
                                      },
                                      fontSize: "0.8rem",
                                    },
                                  },
                                }}
                                arrow
                              >
                                <span
                                  className="timeline-header"
                                  onClick={() => {
                                    titleCopy(item[1][0].title);
                                  }}
                                >
                                  {item[1][0].title}
                                </span>
                              </Tooltip>
                            </dt>
                            <div className="timeline-body">
                              <dd>
                                <span style={{ whiteSpace: "pre-wrap" }}>
                                  {contentList.content}
                                </span>
                              </dd>
                            </div>
                          </dl>
                          <div className="kw-wrap">
                            {item[0].input_list.product.map(
                              (product: any, index: number) => (
                                <Tooltip
                                  key={index}
                                  title="제품/브랜드에서 입력된 값입니다."
                                  placement="bottom"
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        bgcolor: "common.black",
                                        "& .MuiTooltip-arrow": {
                                          color: "common.black",
                                        },
                                        fontSize: "0.8rem",
                                      },
                                    },
                                  }}
                                  arrow
                                >
                                  <span className="kw-tag product">
                                    #{product}
                                  </span>
                                </Tooltip>
                              )
                            )}
                            {item[0].input_list.keyword.map(
                              (keyword: any, index: number) => (
                                <Tooltip
                                  key={index}
                                  title="테마/키워드/시즌 에서 입력된 값입니다."
                                  placement="bottom"
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        bgcolor: "common.black",
                                        "& .MuiTooltip-arrow": {
                                          color: "common.black",
                                        },
                                        fontSize: "0.8rem",
                                      },
                                    },
                                  }}
                                  arrow
                                >
                                  <span className="kw-tag keyword">
                                    #{keyword}
                                  </span>
                                </Tooltip>
                              )
                            )}
                          </div>
                          <div className="slide-icon-wrap">
                            <Copy
                              userState={props.userState}
                              copyText={item[2][0].content}
                            />
                            <Like
                              pk={item[0].pk}
                              range={item[0].range}
                              category={"main"}
                              subRange={item[0].range}
                              userState={props.userState}
                              inputList={item[0].input_list}
                              getHistoryInfo={get_history_info}
                            />
                            <UnLike
                              pk={item[0].pk}
                              range={item[0].range}
                              category={"main"}
                              subRange={item[0].range}
                              userState={props.userState}
                              inputList={item[0].input_list}
                              getHistoryInfo={get_history_info}
                            />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </Stack>
        </div>
      ))}
    </div>
  );
};

export default forwardRef(HistoryResult);
