import { appConfig } from "../../../config/Config";
interface propsType {
  answer: string;
  date: string;
}

const QnAAnswerBox = (props: propsType) => {
  return (
    <tr>
      <td colSpan={4}>
        <div
          className="answer-box"
          style={{ whiteSpace: "pre-wrap", overflow: "auto" }}
        >
          <span className="index-icon index-a">A</span>
          <span>안녕하세요. AI 카피라이터 {appConfig.systemName}입니다.</span>
          <br />
          <br />
          <span>{props.answer}</span>

          <span className="date">{props.date}</span>
        </div>
      </td>
    </tr>
  );
};

export default QnAAnswerBox;
