import { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { PolarArea } from "react-chartjs-2";

interface propsType {
  dataRows: any;
}

const chartBgColor: any = [
  "rgba(255, 99, 132, 0.5)",
  "rgba(54, 162, 235, 0.5)",
  "rgba(255, 206, 86, 0.5)",
  "rgba(75, 192, 192, 0.5)",
  "rgba(153, 102, 255, 0.5)",
  "rgba(255, 159, 64, 0.5)",
  "rgba(30, 159, 240, 0.5)",
];

Chart.register(CategoryScale);

const PolarChart = (props: propsType) => {
  const [chartData, setChartData] = useState({
    labels: props.dataRows.map((data: any) => data.keyword),
    datasets: [
      {
        label: "aa",
        data: props.dataRows.map((data: any) => data.cnt),
        backgroundColor: chartBgColor,
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    if (props.dataRows.length > 0) {
      let newDataRows = [...props.dataRows];
      setChartData({
        labels: newDataRows.map((data: any) => data.keyword),
        datasets: [
          {
            label: "aa",
            data: newDataRows.map((data: any) => data.cnt),
            backgroundColor: chartBgColor,
            borderWidth: 1,
          },
        ],
      });
    }
  }, [props.dataRows]);

  return (
    <PolarArea
      data={chartData}
      options={{
        responsive: true,
        scales: { r: { pointLabels: { display: true, centerPointLabels: true } } },
        plugins: {
          legend: { display: false },
          title: { display: false },
        },
      }}
    />
  );
};

export default PolarChart;
