import { useCallback, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import {
  HttpMainApi,
  GetMyHistoryListParam,
  GetHistoryListParam,
} from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import { appConfig } from "../../config/Config";
import LoadingCopy from "../../utils/LoadingCopy";
import Toast from "../../utils/Toast";
import HistoryResult from "./HistoryResult";
import TooltipProduct from "./TooltipProduct";
import TooltipKeyword from "./TooltipKeyword";
import NotiProduct from "./NotiProduct";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import "./CopyWriter.css";
import NotiKeyword from "./NotiKeyword";
import MultiCopyResult from "./MultiCopyResult";
import Recommendword from "./Recommendword";
import NaverKeywordRolling from "./NaverKeywordRolling";

interface propsType {
  userState: userState;
}

const mainApi = new HttpMainApi();

const dateFrom = dayjs().add(-3, "m").format("YYYY-MM-DD");
const dateTo = dayjs().format("YYYY-MM-DD");

const MultiCopyWriter = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const resultRef0: any = useRef();
  const resultRef1: any = useRef();
  const [productText, setProductText] = useState("");
  const [keywordText, setKeywordText] = useState("");
  const [placeholderData1, setPlaceholderData1] = useState("신메뉴");
  const [placeholderData2, setPlaceholderData2] =
    useState("계란조림");

  const [showResult, setShowResult] = useState(false);

  const [myHistoryList, setMyHistoryList] = useState<any>([]);
  const [myHistoryAddList, setMyHistoryAddList] = useState(1);

  const [historyList, setHistoryList] = useState<any>([]);
  const [historyAddList, setHistoryAddList] = useState(1);

  // 자식컴포넌트 결과
  const [resultClova0, setResultClova0] = useState("");
  const [resultClova1, setResultClova1] = useState("");

  const fncMakeCopy = useCallback(async () => {
    if (isLoading) return;
    if (productText === "") {
      toastRef.current?.toast("제품 / 브랜드 값을 입력하세요.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (keywordText === "") {
      toastRef.current?.toast(
        "테마 / 키워드 / 시즌 값을 입력하세요.",
        "error",
        3000,
        {
          vertical: "top",
          horizontal: "center",
        }
      );
      return;
    }
    setIsLoading(true);

    setResultClova0("");
    setResultClova1("");
    resultRef0.current.changeViewStatus(false);
    resultRef1.current.changeViewStatus(false);
    setShowResult(false);
    setMyHistoryList([]);
    setHistoryList([]);

    resultRef0.current.makeTitle();
    setTimeout(() => {
      resultRef1.current.makeTitle();
    }, 100);
  }, [productText, keywordText, isLoading]);

  // 카피 생성 영역 완료 상태에 따른 명령 수행
  useEffect(() => {
    if (resultClova0 !== "" && resultClova1 !== "") {
      setMyHistoryList([]); // 기존 내가 만든 카피 초기화
      setHistoryList([]); // 기존 히스토리 초기화
      get_my_history_list(); // 내가 만든 카피 조회
      get_history_list(); // 다른사람이 만든 카피 조회
      setIsLoading(false); // 로딩바 제거
      setShowResult(true); // 결과 노출
      resultRef0.current.changeViewStatus(true); // 결과영역 왼쪽 보여주기
      resultRef1.current.changeViewStatus(true); // 결과영역 오른쪽 보여주기
      if (resultClova0 === "fail" || resultClova1 === "fail")
        toastRef.current?.toast(
          "시스템 에러입니다. 관리자에게 문의하세요",
          "error",
          3000,
          {
            vertical: "top",
            horizontal: "center",
          }
        );
    }
  }, [resultClova0, resultClova1]);

  // 카피 생성 영역 완료시 상태 변경
  const finishProc = useCallback((idx: string, flag: string) => {
    if (idx === "1") setResultClova0(flag);
    if (idx === "2") setResultClova1(flag);
  }, []);

  //my history
  const get_my_history_list = useCallback(async () => {
    const param: GetMyHistoryListParam = {
      userId: props.userState.id,
      dataText: productText,
      fromDateText: dateFrom,
      toDateText: dateTo,
    };

    const res = await mainApi.get_my_history_list(param);
    if ("" + res.code === "200") {
      setMyHistoryAddList(10);
      setMyHistoryList(res.response.data_lists);
    } else {
      console.error("get_my_history_list ERROR : ", res.response.error_msg);
    }
  }, [productText, props.userState.id]);

  const get_history_list = useCallback(async () => {
    if (productText === "") {
      toastRef.current?.toast("브랜드 값은 필수입니다.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    const param: GetHistoryListParam = {
      userState: props.userState,
      dataText: productText,
      fromDateText: dateFrom,
      toDateText: dateTo,
    };

    const res = await mainApi.get_history_list(param);
    if ("" + res.code === "200") {
      setHistoryAddList(3);
      setHistoryList(res.response.data_lists);
    } else {
      console.error("get_history_list ERROR : ", res.response.error_msg);
    }
  }, [props.userState, productText]);

  const reloadMyHistory = () => {
    setMyHistoryList([]); // 기존 내가 만든 카피 초기화
    get_my_history_list();
  };

  //부서별 모델 변경
  const [teamType, setTeamType] = useState("A");
  //제품. 브랜드 명칭
  const [titleName, setTitleName] = useState("제품, 메뉴, 브랜드");
  const [keyName, setKeyName] = useState("테마, 키워드");

  const [teamTypeSelect, setTeamTypeSelect] = useState("1");
  const SelecthandleChange = (
    event: React.MouseEvent<HTMLElement>,
    teamTypeSelctValue: string
  ) => {
    setTeamTypeSelect(teamTypeSelctValue);
    setProductText("");
    setKeywordText("");
    if (teamTypeSelctValue === "1") {
      setTeamType("A");
      setPlaceholderData1("신메뉴");
      setPlaceholderData2("계란조림");
      setKeyName("테마, 키워드");
      setTitleName("제품, 메뉴, 브랜드");
    } else if (teamTypeSelctValue === "2") {
      setTeamType("B");
      setPlaceholderData1("두릅, 봄");
      setPlaceholderData2("사전예약, 계절");
      setKeyName("테마, 키워드, 시즌");
      setTitleName("제품, 브랜드");
    } else if (teamTypeSelctValue === "3") {
      setTeamType("C");
      setPlaceholderData1("딸기페어");
      setPlaceholderData2("프로모션, 봄");
      setKeyName("테마, 키워드, 시즌");
      setTitleName("제품, 브랜드");
    } else {
      setKeyName("테마, 키워드, 시즌");
      setTitleName("제품, 메뉴, 브랜드");
    }
  };

  const handleDetailRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedValue2 = event.target.value;
    setTeamType(selectedValue2);
    setProductText("");
    setKeywordText("");
    if (selectedValue2 === "D") {
      setTitleName("메뉴 이름");
      setPlaceholderData1("능이오리백숙");
      setPlaceholderData2("초복");
    }
    if (selectedValue2 === "E") {
      setKeyName("테마, 키워드");
      setTitleName("제품, 메뉴, 브랜드");
      setPlaceholderData1("안동찜닭");
      setPlaceholderData2("야채, 달콤");
    } 
    if (selectedValue2 === "F") {
      setKeyName("n가지, 추천");
      setTitleName("기획전 이름");
      setPlaceholderData1("우리가족, 건강맞춤");
      setPlaceholderData2("4가지, 추천");
    }
    if (selectedValue2 === "G") {
      setKeyName("테마, 키워드, 시즌");
      setTitleName("제품, 브랜드");
      setPlaceholderData1("블루베리");
      setPlaceholderData2("페스츄리");
    }
    if (selectedValue2 === "H") {
      setKeyName("테마, 키워드");
      setTitleName("제품, 메뉴, 브랜드");
      setPlaceholderData1("봄, 봄나물");
      setPlaceholderData2("제철 메뉴");
    }
    if (selectedValue2 === "I") {
      setKeyName("테마, 키워드");
      setTitleName("제품, 메뉴, 브랜드");
      setPlaceholderData1("착즙주스");
      setPlaceholderData2("여름, 일상");
    }
    if (selectedValue2 === "J") {
      setKeyName("테마, 키워드");
      setTitleName("제품, 메뉴, 브랜드");
      setPlaceholderData1("식전빵, 시나몬 버터");
      setPlaceholderData2("무한 리필, 수제");
    }
  };
  //추천 검색 추가
  const recommendRef = useRef<{
    get_recommendword: () => void;
    get_season_recommendword: () => void;
    get_common_recommendword: () => void;
  }>(null);

  const recommendBlur = () => {
    if (productText === "") {
      return;
    }
    if (recommendRef.current) {
      recommendRef.current.get_recommendword();
      recommendRef.current.get_season_recommendword();
      recommendRef.current.get_common_recommendword();
    }
  };

  return (
    <>
      <Box sx={{ p: 0.5 }}>
        <Stack direction="column" spacing={2}>
          {/* 카피소재영역 */}
          <Box component={"div"} sx={{ marginTop: "0.7rem" }}></Box>
          <Box component={"div"}>
            <Stack direction="row" spacing={1}>
              <CardMedia
                component="img"
                image={"/images/icon-cpwt.png"}
                sx={{
                  width: "31px",
                  height: "38px",
                  objectFit: "contain",
                  filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.15))",
                }}
              />
              <Typography
                sx={{
                  display: "inline",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
                component="span"
              >
                카피의 소재를 알려주세요
              </Typography>
              <NaverKeywordRolling />
            </Stack>
          </Box>
          <Paper
            sx={{ p: 2, borderRadius: "1rem", mb: "3rem !important" }}
            elevation={3}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <Stack direction="row" spacing={0.5}>
                  <Typography component="span" className="typo-h4">
                    {titleName}
                  </Typography>
                  <TooltipProduct />
                </Stack>
                <Box component="div" className="copy-input">
                  <TextField
                    id="txtProduct"
                    size="small"
                    placeholder={placeholderData1}
                    value={productText}
                    autoComplete="on"
                    onChange={(e) => {
                      setProductText(e.target.value);
                    }}
                    fullWidth
                    inputProps={{ tabIndex: 1 }}
                    onBlur={recommendBlur}
                  />
                </Box>

                <Stack direction="row">
                  <div className="category">
                    <ToggleButtonGroup
                      color="primary"
                      value={teamTypeSelect}
                      exclusive
                      onChange={SelecthandleChange}
                      aria-label="Platform"
                    >
                      <ToggleButton className="cate-tab" value="1">
                        FS
                      </ToggleButton>
                      <ToggleButton className="cate-tab" value="2">
                        그리팅
                      </ToggleButton>
                      <ToggleButton className="cate-tab" value="3">
                        외식
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </Stack>
                <Stack direction="row">
                  {teamTypeSelect === "1" && (
                    <div className="category">
                      <FormControl>
                        <FormLabel id="buttons-group-label1"></FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="buttons-group-label1"
                          defaultValue="A"
                          name="row-radio-buttons-group"
                          value={teamType}
                          onChange={handleDetailRadioChange}
                        >
                          <FormControlLabel
                            value="A"
                            control={<Radio />}
                            label="광고카피"
                            className={teamType === "A" ? "radio_color" : ""}
                          />
                          <FormControlLabel
                            value="D"
                            control={<Radio />}
                            label="메뉴설명"
                            className={teamType === "D" ? "radio_color" : ""}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}

                  {teamTypeSelect === "2" && (
                    <div className="category">
                      <FormControl>
                        <FormLabel id="buttons-group-label2"></FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="buttons-group-label2"
                          defaultValue="H"
                          name="row-radio-buttons-group"
                          value={teamType}
                          onChange={handleDetailRadioChange}
                        >
                          <FormControlLabel
                            value="B"
                            control={<Radio />}
                            label="광고카피"
                            className={teamType === "B" ? "radio_color" : ""}
                          />
                          <FormControlLabel
                            value="E"
                            control={<Radio />}
                            label="짧은문구"
                            className={teamType === "E" ? "radio_color" : ""}
                          />
                          <FormControlLabel
                            value="F"
                            control={<Radio />}
                            label="기획전추천"
                            className={teamType === "F" ? "radio_color" : ""}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}

                  {teamTypeSelect === "3" && (
                    <div className="category">
                      <FormControl>
                        <FormLabel id="buttons-group-label2"></FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="buttons-group-label2"
                          defaultValue="L"
                          name="row-radio-buttons-group"
                          value={teamType}
                          onChange={handleDetailRadioChange}
                        >
                          <FormControlLabel
                            value="C"
                            control={<Radio />}
                            label="레스토랑"
                            className={teamType === "C" ? "radio_color" : ""}
                          />
                          <FormControlLabel
                            value="G"
                            control={<Radio />}
                            label="베즐리"
                            className={teamType === "G" ? "radio_color" : ""}
                          />
                          <FormControlLabel
                            value="J"
                            control={<Radio />}
                            label="텍사스"
                            className={teamType === "J" ? "radio_color" : ""}
                          />
                          <FormControlLabel
                            value="H"
                            control={<Radio />}
                            label="푸드코트"
                            className={teamType === "H" ? "radio_color" : ""}
                          />
                          <FormControlLabel
                            value="I"
                            control={<Radio />}
                            label="조앤더주스"
                            className={teamType === "I" ? "radio_color" : ""}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Stack direction="row" spacing={0.5}>
                  <Typography component="span" className="typo-h4">
                    {keyName}
                  </Typography>
                  <TooltipKeyword />
                </Stack>
                <Box component="div" className="copy-input">
                  <TextField
                    id="txtKeyword"
                    size="small"
                    placeholder={placeholderData2}
                    value={keywordText}
                    autoComplete="on"
                    onChange={(e) => {
                      setKeywordText(e.target.value);
                    }}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        if (isLoading) return;
                        fncMakeCopy();
                        e.preventDefault();
                      }
                    }}
                    fullWidth
                    inputProps={{ tabIndex: 2 }}
                  />
                </Box>
                <Recommendword
                  ref={recommendRef}
                  productText={productText}
                  keywordText={keywordText}
                  setKeywordText={setKeywordText}
                  teamType={teamType}
                />
                <NotiKeyword />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Stack direction="row" spacing={0.5}>
                  <Typography
                    component="span"
                    className="typo-h4 color-white h-23"
                  >
                    {" "}
                  </Typography>
                </Stack>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ fontSize: "1rem" }}
                  onClick={fncMakeCopy}
                  tabIndex={2}
                >
                  만들어볼게요
                </Button>
              </Grid>
            </Grid>
          </Paper>
          {/* 카피소재영역 */}
          {/* 결과영역 */}
          <Box
            component={"div"}
            sx={{ ...(!showResult && { display: "none" }) }}
          >
            <Stack direction="row" spacing={1}>
              <CardMedia
                component="img"
                image={"/images/icon-result.png"}
                sx={{
                  width: "31px",
                  height: "38px",
                  objectFit: "contain",
                  filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.15))",
                }}
              />
              <Typography
                sx={{
                  display: "inline",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
                component="span"
              >
                {appConfig.systemNameEng}는 제목과 본문을 이렇게 생각했어요
              </Typography>
            </Stack>
          </Box>
          <Box component={"div"} sx={{ mb: "3rem !important" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <MultiCopyResult
                  ref={resultRef0}
                  userState={props.userState}
                  idx="1"
                  teamType={teamType}
                  dataText={productText}
                  joinText={keywordText}
                  finishProc={finishProc}
                  reloadMyHistory={reloadMyHistory}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MultiCopyResult
                  ref={resultRef1}
                  userState={props.userState}
                  idx="2"
                  teamType={teamType}
                  dataText={productText}
                  joinText={keywordText}
                  finishProc={finishProc}
                  reloadMyHistory={reloadMyHistory}
                />
              </Grid>
            </Grid>
          </Box>
          {/* 결과영역 */}
          {/* My History */}
          <Box
            component={"div"}
            sx={{ ...(!showResult && { display: "none" }) }}
          >
            <Stack direction="row" spacing={1}>
              <CardMedia
                component="img"
                image={"/images/icon-timeline.png"}
                sx={{
                  width: "31px",
                  height: "38px",
                  objectFit: "contain",
                  filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.15))",
                }}
              />
              <Typography
                sx={{
                  display: "inline",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
                component="span"
              >
                내가 만든 카피
              </Typography>
            </Stack>
          </Box>
          <Box component={"div"}>
            <Paper
              sx={{
                p: 2,
                borderRadius: "1rem",
                position: "relative",
                ...(showResult ? { display: "flex" } : { display: "none" }),
              }}
              elevation={3}
            >
              <Stack direction="column" spacing={1.5} sx={{ width: "100%" }}>
                {myHistoryList
                  .slice(0, myHistoryAddList)
                  .map((item: any, index: number) => (
                    <HistoryResult
                      key={"my" + index}
                      userState={props.userState}
                      pk={item.pk}
                      range={item.range}
                    ></HistoryResult>
                  ))}
                <Box sx={{ width: "100%", textAlign: "center" }}>
                  <Button
                    variant="outlined"
                    sx={{
                      fontSize: "1rem",
                      backgroundColor: "#f8f9fa",
                      color: "#1f2d3d",
                      borderColor: "#f8f9fa",
                    }}
                    onClick={() => {
                      setMyHistoryAddList(myHistoryAddList + 5);
                    }}
                  >
                    더보기 +
                  </Button>
                </Box>
              </Stack>
            </Paper>
          </Box>
          {/* My History */}
          {/* Total History */}
          <Box
            component={"div"}
            sx={{
              mt: "3rem !important",
              ...(!showResult && { display: "none" }),
            }}
          >
            <Stack direction="row" spacing={1}>
              <CardMedia
                component="img"
                image={"/images/icon-other.png"}
                sx={{
                  width: "31px",
                  height: "38px",
                  objectFit: "contain",
                  filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.15))",
                }}
              />
              <Typography
                sx={{
                  display: "inline",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
                component="span"
              >
                다른사람들은 {productText} ({keywordText})에 대해서 이렇게
                입력했어요
              </Typography>
            </Stack>
          </Box>
          <Box component={"div"}>
            <Paper
              sx={{
                p: 2,
                borderRadius: "1rem",
                position: "relative",
                ...(showResult ? { display: "flex" } : { display: "none" }),
              }}
              elevation={3}
            >
              <Stack direction="column" spacing={1.5} sx={{ width: "100%" }}>
                {historyList
                  .slice(0, myHistoryAddList)
                  .map((item: any, index: number) => (
                    <HistoryResult
                      key={"His" + index}
                      userState={props.userState}
                      pk={item.main_pk}
                      range={item.main_range}
                    ></HistoryResult>
                  ))}
                <Box sx={{ width: "100%", textAlign: "center" }}>
                  <Button
                    variant="outlined"
                    sx={{
                      fontSize: "1rem",
                      backgroundColor: "#f8f9fa",
                      color: "#1f2d3d",
                      borderColor: "#f8f9fa",
                    }}
                    onClick={() => {
                      setHistoryAddList(historyAddList + 5);
                    }}
                  >
                    더보기 +
                  </Button>
                </Box>
              </Stack>
            </Paper>
          </Box>
          {/* Total History */}
        </Stack>
      </Box>
      <LoadingCopy
        loading={isLoading}
        loadingTexts={[
          `${appConfig.systemNameExam} [${productText}]에 대해서 카피를 생성하고 있어요!`,
          "잠시만 기다려주세요.",
        ]}
        interval={150}
      />
      <Toast ref={toastRef} />
    </>
  );
};

export default MultiCopyWriter;
