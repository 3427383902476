import { useEffect, useState } from "react";

import { userState, inputList } from "../../interface/MainInterface";
import {
  HttpMainApi,
  GetLikeInfoParam,
  PutLikeInfoParam,
} from "../../interface/main-api";

interface propsType {
  pk: string;
  range: string;
  category: string;
  subRange: string;
  userState: userState;
  inputList: inputList;
  callBack: any;
}

const mainApi = new HttpMainApi();

const MultiLike = (props: propsType) => {
  const [className, setClassName] = useState("fas fa-icon fa-thumbs-up");

  const setClickLike = async () => {
    const param: PutLikeInfoParam = {
      pk: props.pk,
      range: props.range,
      category: props.category,
      subRange: props.subRange,
      userState: props.userState,
      inputList: props.inputList,
    };
    const res = await mainApi.put_like(param);
    if ("" + res.code === "200") {
      res.response.result === "Y"
        ? setClassName("fas fa-icon fa-thumbs-up active")
        : setClassName("fas fa-icon fa-thumbs-up");
      props.callBack();
    } else {
      console.error("setClickLike ERROR : ", res.response.error_msg);
    }
  };

  const get_like_info = async () => {
    const param: GetLikeInfoParam = {
      userState: props.userState,
      range: props.range,
    };
    const res = await mainApi.get_like_info(param);
    if ("" + res.code === "200") {
      res.response.result === "Y"
        ? setClassName("fas fa-icon fa-thumbs-up active")
        : setClassName("fas fa-icon fa-thumbs-up");
    } else {
      console.error("get_like_info ERROR : ", res.response.error_msg);
    }
  };

  useEffect(() => {
    get_like_info();
  }, []); //[] 변경시마다  작동

  return <i className={className} onClick={setClickLike} />;
};

export default MultiLike;
