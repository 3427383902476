import Stack from "@mui/material/Stack";
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";
import { appConfig } from "../../config/Config";

import "./CopyWriter.css";

const NotiKeyword = () => {
  return (
    <>
      <Stack direction="row">
        <ErrorIcon className="copy-info-caution-icon" />
        <Stack direction="column">
          <Typography color="inherit" className="copy-info-caution">
            글링의 제안은 사실과 다를 수 있으니 꼭 확인해주세요.
          </Typography>
          <Typography color="inherit" className="copy-info-caution-more">
            대표적인 키워드 위주 입력하시고, 나오는 결과에 회사명이나 점정보를
            대입하여 카피를 만드시는게 좋습니다.
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row">
        <ErrorIcon className="copy-info-caution-icon" />
        <Stack direction="column">
          <Typography color="inherit" className="copy-info-caution">
            문장을 만드는 과정에서 타사명, 연관 없는 제품명 등이 나올 수
            있습니다. 당사에 맞게 변환해서 사용하세요.
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default NotiKeyword;
