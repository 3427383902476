import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Toast from "../../utils/Toast";

interface propsType {
  callBack: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

const AskPassword = (props: propsType, ref: any) => {
  let navigate = useNavigate();
  const toastRef: any = useRef();
  const inputRef: any = useRef();

  const [openModal, setOpenModal] = useState(true);
  const [password, setPassword] = useState("");
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setOpenModal(true);
      setPassword("");
    };
  }, []);

  const checkPassword = async () => {
    if (password === "" || password.trim() === "") {
      toastRef.current?.toast("비밀번호를 입력하세요.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    if (password === "flxpdlfxpzm") {
      toastRef.current?.toast("관리자님 안녕하세요.", "info", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      handleCloseModal();
      props.callBack(true);
    } else {
      toastRef.current?.toast("비밀번호가 맞지 않습니다.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const handlePasswordEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") checkPassword();
  };

  const handleCancel = () => {
    handleCloseModal();
    props.callBack(false);
    navigate("/");
  };

  useEffect(() => {
    if (openModal) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  }, [openModal]);

  return (
    <Modal open={openModal}>
      <Box sx={style}>
        <Box
          sx={{ color: "white", background: "#384e45", padding: "10px 20px" }}
        >
          관리자 메뉴 입장
        </Box>
        <Stack
          sx={{ padding: "25px 30px", alignItems: "center" }}
          spacing={1}
          direction="column"
        >
          <Typography variant="h6" component="h2" sx={{ fontSize: "1rem" }}>
            관리자 기능입니다. 비밀번호를 입력하세요.
          </Typography>
          <TextField
            label="비밀번호"
            className="bt-md"
            value={password}
            variant="outlined"
            size="small"
            type="password"
            autoComplete="off"
            inputProps={{
              id: "input-Password",
              enterKeyHint: "Enter",
              autoFocus: true,
            }}
            fullWidth
            autoFocus={true}
            onKeyDown={(e) => {
              handlePasswordEnter(e);
            }}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            sx={{ "& > label": { fontSize: "0.9rem" } }}
            inputRef={inputRef}
          />
        </Stack>
        {/* <Divider /> */}
        <Stack
          spacing={1}
          direction="row"
          sx={{ borderTop: "1px solid #777777" }}
        >
          <Button
            className="bt-md"
            onClick={handleCancel}
            sx={{ width: "50%", borderRadius: 0, color: "black" }}
          >
            취소
          </Button>
          <Button
            className="bt-md"
            onClick={checkPassword}
            sx={{
              width: "50%",
              background: "#333333",
              borderRadius: 0,
              margin: 0,
              color: "white",
            }}
          >
            확인
          </Button>
        </Stack>
        <Toast ref={toastRef} />
      </Box>
    </Modal>
  );
};

export default AskPassword;
