import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AES } from "crypto-js";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Pagination from "@mui/material/Pagination";

import {
  DataGrid,
  GridColDef,
  GridCellParams,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
  GridRowSelectionModel,
  GridRenderCellParams,
  useGridApiRef,
  GridPaginationModel,
} from "@mui/x-data-grid";

import { HttpMainApi, GetQnAListParam } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import { appConfig } from "../../config/Config";
import QnaWrite from "./QnaWrite";
import * as QnaPagenation from "./QnaPageNation";

import "./QnaList.css";

interface propsType {
  userState: userState;
}

interface IGridCols {
  QSEQ: string;
  ORIGIN_SEQ: string;
  ANSWER_YN: string;
  AUTHOR: string;
  TITLE: string;
  CONTENT: string;
  CREATE_DATE: string;
  USER_INFO1: string;
}

const mainApi = new HttpMainApi();
const pageSize: number = 10;

const QnaList = (props: propsType) => {
  let navigate = useNavigate();

  const toastRef: any = useRef();
  const qnaWriteRef: any = useRef();
  const gridApi = useGridApiRef();
  const [isLoading, setIsLoading] = useState(false);

  const [pInfo, setPInfo] = useState<any>();
  const [rInfo, setRinfo] = useState<any>();
  const [selectedInfo, setSelectedInfo] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<GridRowSelectionModel>([]);
  const [selectedPage, setSelectedPage] = useState<GridPaginationModel>({ pageSize: 10, page: 0 });
  const [answerCnt, setAnswerCnt] = useState(0);
  const [dataRows, setDataRows] = useState<IGridCols[]>([]);
  const dataColumn: GridColDef[] = [
    {
      field: "ANSWER_YN",
      headerName: "상태",
      headerAlign: "center",
      align: "center",
      width: 120,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <span className={param.row.ANSWER_YN === "Y" ? "color-999" : ""}>
            {param.row.ANSWER_YN === "Y" ? "답변완료" : "접수완료"}
          </span>
        );
      },
    },
    { field: "TITLE", headerName: "제목", headerAlign: "center", align: "left", flex: 1 },
    { field: "AUTHOR", headerName: "글쓴이", headerAlign: "center", align: "center", width: 200 },
    { field: "CREATE_DATE", headerName: "등록일", headerAlign: "center", align: "center", width: 200 },
  ];

  function CustomPagination() {
    const page = useGridSelector(gridApi, gridPageSelector);
    const pageCount = useGridSelector(gridApi, gridPageCountSelector);
    return (
      <Box className="qna-pagenation">
        <Pagination
          color="primary"
          shape="rounded"
          count={pageCount === 0 ? 1 : pageCount}
          page={page + 1}
          siblingCount={10}
          onChange={(e, v) => gridApi.current.setPage(v - 1)}
        ></Pagination>
      </Box>
    );
  }

  const fncGetQnaList = useCallback(async () => {
    const param: GetQnAListParam = {
      userId: props.userState.id,
      userInfo1: props.userState.userInfo1
    };

    setIsLoading(true);

    const res = await mainApi.get_my_qna_list(param);
    if ("" + res.code === "200") {
      setDataRows(res.response.qna_list);
      let tmpAnswerCnt = 0;
      for (const info of res.response.qna_list) {
        if (info.ANSWER_YN === "Y") tmpAnswerCnt++;
      }
      setAnswerCnt(tmpAnswerCnt);
    } else {
      toastRef.current?.toast("조회 중 에러가 발생했습니다.", "error", 3000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  }, [props.userState.id]);

  const fncReload = useCallback(
    (flag: boolean) => {
      if (flag)
        toastRef.current?.toast("문의 등록을 완료했습니다.", "info", 3000, { vertical: "top", horizontal: "center" });
      fncGetQnaList();
    },
    [fncGetQnaList]
  );

  const fncCellClick = (param: GridCellParams) => {
    setSelectedRow([param.row.QSEQ]);
    setSelectedInfo({ qInfo: param.row, pInfo: selectedPage, rInfo: param.row.QSEQ });
  };

  useEffect(() => {
    if (selectedInfo) {
      QnaPagenation.setQnaPageInfo(selectedInfo);
      navigate("/qna/detail", { state: selectedInfo });
    }
  }, [selectedInfo, navigate]);

  useEffect(() => {
    if (pInfo && rInfo) {
      setSelectedPage(pInfo);
      setSelectedRow([rInfo]);
    }
  }, [pInfo, rInfo]);

  useEffect(() => {
    const pageInfo = QnaPagenation.getQnaPageInfo();
    if (pageInfo !== null) {
      if (pageInfo.pInfo !== undefined && pageInfo.rInfo !== undefined) {
        setPInfo(pageInfo.pInfo);
        setRinfo(pageInfo.rInfo);
      }
    }

    fncGetQnaList();
  }, []);

  return (
    <>
      <Box sx={{ p: 0.5 }}>
        <Stack direction="column" spacing={2}>
          <Box component={"div"} sx={{ marginTop: "0.7rem" }}></Box>
          <Box component={"div"}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Stack direction="row" spacing={1}>
                  <CardMedia
                    component="img"
                    image={"/images/icon-other-qna.png"}
                    sx={{
                      width: "31px",
                      height: "38px",
                      objectFit: "contain",
                      filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.15))",
                    }}
                  />
                  <Typography sx={{ display: "inline", fontWeight: "bold", fontSize: "1.5rem" }} component="span">
                    무엇을 도와드릴까요?
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    qnaWriteRef.current.open(true);
                  }}
                >
                  문의추가
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Paper sx={{ p: 3, borderRadius: "1rem" }} elevation={3}>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={2}>
                <Typography className="case-num" component="span">
                  총 문의 : <strong>{dataRows.length}</strong>건
                </Typography>
                <Divider orientation="vertical" variant="middle" flexItem className="case-num-divider" />
                <Typography className="case-num" component="span">
                  답변완료 : <strong>{answerCnt}</strong>건
                </Typography>
                <Divider orientation="vertical" variant="middle" flexItem className="case-num-divider" />
                <Typography className="case-num" component="span">
                  문의접수 : <strong>{dataRows.length - answerCnt}</strong>건
                </Typography>
              </Stack>
              <div className="datatable-qna">
                <DataGrid
                  apiRef={gridApi}
                  rows={dataRows}
                  columns={dataColumn}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: pageSize,
                      },
                    },
                    sorting: {
                      sortModel: [
                        {
                          field: "range_num",
                          sort: "asc",
                        },
                      ],
                    },
                  }}
                  slots={{ pagination: CustomPagination }}
                  hideFooterSelectedRowCount
                  getRowId={(row) => row.QSEQ}
                  rowSelectionModel={selectedRow}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRow(newRowSelectionModel);
                  }}
                  paginationModel={selectedPage}
                  onPaginationModelChange={(newPagenationModel) => {
                    setSelectedPage(newPagenationModel);
                  }}
                  onCellClick={fncCellClick}
                />
              </div>
              {/* <CustomPagination></CustomPagination> */}
            </Stack>
          </Paper>
        </Stack>
        <LoadingCircle loading={isLoading} />
        <Toast ref={toastRef} />
        <QnaWrite ref={qnaWriteRef} userState={props.userState} reload={fncReload} />
      </Box>
    </>
  );
};

export default QnaList;
