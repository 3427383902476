/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useCallback, useRef } from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import MuiInput from "@mui/material/Input";
import Tooltip from "@mui/material/Tooltip";

import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridCellParams,
  GridRowSelectionModel,
  useGridApiRef,
} from "@mui/x-data-grid";

import {
  HttpMainApi,
  SetModelInfo,
  DelModelInfo,
} from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import AskPassword from "./AskPassword";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import "./MngModel.css";

interface propsType {
  userState: userState;
}

interface IGridCols {
  request_id: string;
  aatag: string;
}

const mainApi = new HttpMainApi();

const Input = styled(MuiInput)`
  width: 55px;
`;

const MngModel = (props: propsType) => {
  const toastRef: any = useRef();
  const gridApi = useGridApiRef();

  const [isLoading, setLoading] = useState(false);

  const [searchRequestId, setSearchRequestId] = useState("");
  const [requestId, setRequestId] = useState("");
  const [gubun, setGubun] = useState("");
  const [aatag, setAatag] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [apiKeyPrimaryVal, setapiKeyPrimaryVal] = useState("");
  const [host, setHost] = useState("");
  const [postUrl, setPostUrl] = useState("");
  const [presetText, setPresetText] = useState("");
  const [topP, setTopP] = useState<any>(0.8);
  const [topK, setTopK] = useState<any>(0);
  const [maxToken, setMaxToken] = useState<any>(200);
  const [temperature, setTemperature] = useState<any>(0.5);
  const [repeatPenalty, setRepeatPenalty] = useState<any>(2);
  const [stopSequences, setStopSequences] = useState<any>("");
  const [injectStartText, setInjectStartText] = useState("");
  const [injectRestartText, setInjectRestartText] = useState("");

  const [dataRows, setDataRows] = useState<IGridCols[]>([]);
  const dataColumn: GridColDef[] = [
    {
      field: "request_id",
      headerName: "request_id",
      headerAlign: "center",
      align: "left",
      flex: 1,
    },
    {
      field: "aatag",
      headerName: "aatag",
      headerAlign: "center",
      align: "center",
      width: 100,
    },
  ];

  const [selectedRow, setSelectedRow] = useState<GridRowSelectionModel>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "request_id",
        operator: "contains",
        value: searchRequestId,
      },
    ],
  });

  const fncGetList = useCallback(async () => {
    setLoading(true);
    const res = await mainApi.get_model_list();
    if ("" + res.code === "200") {
      setDataRows(res.response.model_list);
    } else {
      toastRef.current?.toast(
        "모델목록 조회 중 에러가 발생했습니다.",
        "error",
        3000,
        {
          vertical: "top",
          horizontal: "center",
        }
      );
      console.error("fncModelList ERROR : ", res.response.error_msg);
    }
    setLoading(false);
  }, []);

  const fncSaveModel = useCallback(async () => {
    if (
      requestId === "" ||
      gubun === "" ||
      aatag === "" ||
      apiKey === "" ||
      apiKeyPrimaryVal === "" ||
      host === "" ||
      postUrl === "" ||
      stopSequences === "" ||
      injectStartText === "" ||
      injectRestartText === ""
    ) {
      toastRef.current?.toast("필수값(*)을 입력하세요.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    if (!window.confirm("자료를 저장하시겠습니까?")) return;
    const param: SetModelInfo = {
      model_info: {
        aatag: aatag,
        api_key: apiKey,
        api_key_primary_val: apiKeyPrimaryVal,
        gubun: gubun,
        host: host,
        post_url: postUrl,
        preset_text: presetText,
        request_data: {
          includeAiFilters: "True",
          includeProbs: "False",
          includeTokens: "True",
          maxTokens: maxToken,
          repeatPenalty: repeatPenalty,
          restart: injectRestartText,
          start: injectStartText,
          stopBefore: stopSequences,
          temperature: temperature,
          text: "preset_text",
          topK: topK,
          topP: topP,
        },
        request_id: requestId,
      },
    };

    const res = await mainApi.set_model_info(param);
    if ("" + res.code === "200") {
      toastRef.current?.toast("저장이 성공했습니다.", "info", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      fncGetList();
    } else {
      toastRef.current?.toast("저장 중 에러가 발생했습니다.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      console.error("fncSaveModel ERROR : ", res.response.error_msg);
    }
  }, [
    requestId,
    gubun,
    aatag,
    apiKey,
    apiKeyPrimaryVal,
    host,
    postUrl,
    stopSequences,
    injectStartText,
    injectRestartText,
    topK,
    topP,
    maxToken,
    temperature,
    repeatPenalty,
    presetText,
    fncGetList,
  ]);

  const fncDeleteModel = useCallback(async () => {
    if (requestId === "" || gubun === "") {
      toastRef.current?.toast("삭제할 모델을 선택하세요.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    if (
      !window.confirm(
        "모델을 삭제하시겠습니까? 삭제한 데이터는 복구할 수 없습니다."
      )
    )
      return;
    const param: DelModelInfo = {
      request_id: requestId,
      gubun: gubun,
    };

    const res = await mainApi.del_model_info(param);
    if ("" + res.code === "200") {
      toastRef.current?.toast("삭제가 성공했습니다.", "info", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      fncGetList();
    } else {
      if (res.response.error_msg === "model_using")
        toastRef.current?.toast(
          "현재 사용중인 모델입니다. 확인 후 다시 시도하세요",
          "error",
          3000,
          {
            vertical: "top",
            horizontal: "center",
          }
        );
      else
        toastRef.current?.toast("삭제 중 에러가 발생했습니다.", "error", 3000, {
          vertical: "top",
          horizontal: "center",
        });
      console.error("fncDeleteModel ERROR : ", res.response.error_msg);
    }
  }, [requestId, gubun, fncGetList]);

  const fncCellClick = useCallback((param: GridCellParams) => {
    setRequestId(param.row.request_id);
    setGubun(param.row.gubun);
    setAatag(param.row.aatag);
    setApiKey(param.row.api_key);
    setapiKeyPrimaryVal(param.row.api_key_primary_val);
    setHost(param.row.host);
    setPostUrl(param.row.post_url);
    setPresetText(param.row.preset_text);
    setTopP(param.row.request_data.topP);
    setTopK(param.row.request_data.topK);
    setMaxToken(param.row.request_data.maxTokens);
    setTemperature(param.row.request_data.temperature);
    setRepeatPenalty(param.row.request_data.repeatPenalty);
    setStopSequences(
      param.row.request_data.stopBefore.toString().replaceAll("\n", "↵")
    );
    setInjectStartText(param.row.request_data.start.replaceAll("\n", "↵"));
    setInjectRestartText(param.row.request_data.restart.replaceAll("\n", "↵"));
  }, []);

  const handleTopPChange = useCallback(
    (event: Event, newValue: number | number[]) => {
      setTopP(newValue);
    },
    []
  );

  const handleInputTopPChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTopP(event.target.value === "" ? 0 : Number(event.target.value));
    },
    []
  );

  const handleInputTopPBlur = useCallback(() => {
    if (topP < 0) {
      setTopP(0);
    } else if (topP > 1) {
      setTopP(1);
    }
  }, [topP]);

  const handleTopKChange = useCallback(
    (event: Event, newValue: number | number[]) => {
      setTopK(newValue);
    },
    []
  );

  const handleInputTopKChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTopK(event.target.value === "" ? 0 : Number(event.target.value));
    },
    []
  );

  const handleInputTopKBlur = useCallback(() => {
    if (topK < 0) {
      setTopK(0);
    } else if (topK > 128) {
      setTopK(128);
    }
  }, [topK]);

  const handleMaxTokenChange = useCallback(
    (event: Event, newValue: number | number[]) => {
      setMaxToken(newValue);
    },
    []
  );

  const handleInputMaxTokenChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setMaxToken(event.target.value === "" ? 0 : Number(event.target.value));
    },
    []
  );

  const handleInputMaxTokenBlur = useCallback(() => {
    if (maxToken < 0) {
      setMaxToken(0);
    } else if (maxToken > 128) {
      setMaxToken(128);
    }
  }, [maxToken]);

  const handleTemperatureChange = useCallback(
    (event: Event, newValue: number | number[]) => {
      setTemperature(newValue);
    },
    []
  );

  const handleInputTemperatureChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTemperature(
        event.target.value === "" ? 0 : Number(event.target.value)
      );
    },
    []
  );

  const handleInputTemperatureBlur = useCallback(() => {
    if (temperature < 0) {
      setTemperature(0);
    } else if (temperature > 1) {
      setTemperature(1);
    }
  }, [temperature]);

  const handleRepeatPenaltyChange = useCallback(
    (event: Event, newValue: number | number[]) => {
      setRepeatPenalty(newValue);
    },
    []
  );

  const handleInputRepeatPenaltyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRepeatPenalty(
        event.target.value === "" ? 0 : Number(event.target.value)
      );
    },
    []
  );

  const handleInputRepeatPenaltyBlur = useCallback(() => {
    if (repeatPenalty < 0) {
      setRepeatPenalty(0);
    } else if (repeatPenalty > 10) {
      setRepeatPenalty(10);
    }
  }, [repeatPenalty]);

  // 로그인 체크
  const [view, setView] = useState(false);
  useEffect(() => {
    if (!props.userState.id) {
      window.location.href = "/login";
    }
    if (props.userState.userType !== "1") {
      window.location.href = "/";
    } else {
      setView(true);
    }
  }, [props.userState]);

  useEffect(() => {
    fncGetList();
  }, [fncGetList]);

  const [passwordConfirm, setPasswordConfirm] = useState(false);
  const pwdCallback = (result: boolean) => {
    setPasswordConfirm(result);
  };
  return (
    <div>
      {!passwordConfirm ? (
        <AskPassword callBack={pwdCallback} />
      ) : (
        <Grid container spacing={1} sx={{ paddingX: 1 }} component="div">
          {/* Title */}
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              모델관리
            </Typography>
          </Grid>
          {/* Title */}
          {/* Left */}
          <Grid item xs={12} sm={3}>
            <Stack spacing={1} direction="column">
              {/* 검색조건 */}
              <Paper sx={{ p: 2 }} elevation={5}>
                <Stack spacing={1} direction="column">
                  <Stack spacing={1} direction="row">
                    <Grid item xs={8}>
                      <Stack spacing={1} direction="row">
                        <TextField
                          id="txtSearchRequestId"
                          label="Request ID"
                          variant="outlined"
                          size="small"
                          value={searchRequestId}
                          type="search"
                          autoComplete="off"
                          inputProps={{ enterKeyHint: "Enter" }}
                          onChange={(e) => {
                            setSearchRequestId(e.target.value);
                          }}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") fncGetList();
                          }}
                          fullWidth
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                      >
                        <Button variant="contained" onClick={fncGetList}>
                          검색
                        </Button>
                      </Stack>
                    </Grid>
                  </Stack>
                </Stack>
              </Paper>
              {/* 검색조건 */}
              {/* Main Grid */}
              <Paper sx={{ p: 2 }} elevation={5}>
                <div className="datatable-models">
                  <DataGrid
                    apiRef={gridApi}
                    rows={dataRows}
                    columns={dataColumn}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 100,
                        },
                      },
                    }}
                    pageSizeOptions={[3, 10, 20, 50, 100]}
                    getRowId={(row) => row.request_id}
                    rowSelectionModel={selectedRow}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setSelectedRow(newRowSelectionModel);
                    }}
                    filterModel={filterModel}
                    onFilterModelChange={(newFilterModel) =>
                      setFilterModel(newFilterModel)
                    }
                    onCellClick={fncCellClick}
                  />
                </div>
              </Paper>
              {/* Main Grid */}
            </Stack>
          </Grid>
          {/* Right */}
          <Grid item xs={12} sm={4}>
            <Paper sx={{ p: 2, pb: 4 }} elevation={5}>
              <Stack spacing={1} direction="column">
                <Stack spacing={1} direction="row">
                  <TextField
                    id="txtRequestId"
                    label="Request ID"
                    variant="outlined"
                    size="small"
                    value={requestId}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    onChange={(e) => {
                      setRequestId(e.target.value);
                    }}
                    required
                    fullWidth
                  />
                  <TextField
                    id="txtGubun"
                    label="Gubun"
                    variant="outlined"
                    size="small"
                    value={gubun}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    onChange={(e) => {
                      setGubun(e.target.value);
                    }}
                    required
                  />
                </Stack>
                <TextField
                  id="txtAatag"
                  label="aatag"
                  variant="outlined"
                  size="small"
                  value={aatag}
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter" }}
                  onChange={(e) => {
                    setAatag(e.target.value);
                  }}
                  fullWidth
                  required
                />
                <TextField
                  id="txtApiKey"
                  label="API Key"
                  variant="outlined"
                  size="small"
                  value={apiKey}
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter" }}
                  onChange={(e) => {
                    setApiKey(e.target.value);
                  }}
                  fullWidth
                  required
                />
                <TextField
                  id="txtApiKeyPrimaryVal"
                  label="API Key Primary Val"
                  variant="outlined"
                  size="small"
                  value={apiKeyPrimaryVal}
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter" }}
                  onChange={(e) => {
                    setapiKeyPrimaryVal(e.target.value);
                  }}
                  fullWidth
                  required
                />
                <Stack spacing={1} direction="row">
                  <TextField
                    id="txtHost"
                    label="Host"
                    variant="outlined"
                    size="small"
                    value={host}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    onChange={(e) => {
                      setHost(e.target.value);
                    }}
                    fullWidth
                    required
                  />
                  <TextField
                    id="txtPostUrl"
                    label="Post URL"
                    variant="outlined"
                    size="small"
                    value={postUrl}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    onChange={(e) => {
                      setPostUrl(e.target.value);
                    }}
                    fullWidth
                    required
                  />
                </Stack>
                <Stack spacing={0} direction="column">
                  <Stack spacing={1} direction="row">
                    <Grid item xs={8}>
                      <Tooltip
                        title={
                          "언어모델이 결과 생성을 시도한 전체 횟수에서 특정 토큰이 생성된 횟수가 얼마나 되는지 확인하여 해당 토큰이 생성될 확률을 계산합니다. 이때, 기준 값 P를 설정하여 특정 토큰의 생성 확률이 P에 못 미치면 결과에서 제외합니다."
                        }
                        componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: "1.0rem",
                            },
                          },
                        }}
                      >
                        <Typography id="input-slider" gutterBottom>
                          Top P
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                      >
                        <Input
                          value={topP}
                          size="small"
                          onChange={handleInputTopPChange}
                          onBlur={handleInputTopPBlur}
                          inputProps={{
                            step: 0.01,
                            min: 0,
                            max: 1,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Stack>
                  <Slider
                    size="small"
                    value={topP}
                    aria-label="Small"
                    step={0.01}
                    min={0}
                    max={1}
                    onChange={handleTopPChange}
                  />
                </Stack>
                <Stack spacing={0} direction="column">
                  <Stack spacing={1} direction="row">
                    <Grid item xs={8}>
                      <Tooltip
                        title={
                          "적합도가 가장 높은 순서대로 K번째 토큰까지만 결과에 포함하고 나머지 토큰은 제외합니다. 예를들어, K값을 1로 설정하면 가장 적합도가 높은 토큰 1개를 사용합니다."
                        }
                        componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: "1.0rem",
                            },
                          },
                        }}
                      >
                        <Typography id="input-slider" gutterBottom>
                          Top K
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                      >
                        <Input
                          value={topK}
                          size="small"
                          onChange={handleInputTopKChange}
                          onBlur={handleInputTopKBlur}
                          inputProps={{
                            step: 1,
                            min: 0,
                            max: 128,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Stack>
                  <Slider
                    size="small"
                    value={topK}
                    aria-label="Small"
                    step={1}
                    min={0}
                    max={128}
                    onChange={handleTopKChange}
                  />
                </Stack>
                <Stack spacing={0} direction="column">
                  <Stack spacing={1} direction="row">
                    <Grid item xs={8}>
                      <Tooltip
                        title={
                          "결과값을 생성할 때 사용할 최대 토큰의 수를 설정합니다."
                        }
                        componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: "1.0rem",
                            },
                          },
                        }}
                      >
                        <Typography id="input-slider" gutterBottom>
                          Maximum Tokens
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                      >
                        <Input
                          value={maxToken}
                          size="small"
                          onChange={handleInputMaxTokenChange}
                          onBlur={handleInputMaxTokenBlur}
                          inputProps={{
                            step: 1,
                            min: 0,
                            max: 2048,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Stack>
                  <Slider
                    size="small"
                    value={maxToken}
                    aria-label="Small"
                    step={1}
                    min={0}
                    max={2048}
                    onChange={handleMaxTokenChange}
                  />
                </Stack>
                <Stack spacing={0} direction="column">
                  <Stack spacing={1} direction="row">
                    <Grid item xs={8}>
                      <Tooltip
                        title={
                          "Temperature를 낮게 설정하면 정형적인 결과값을 생성하며, 높게 설정하면 다양한 문장이 만들어질 수는 있지만 문장의 품질이 다소 떨어질 수 있습니다."
                        }
                        componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: "1.0rem",
                            },
                          },
                        }}
                      >
                        <Typography id="input-slider" gutterBottom>
                          Temperature
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                      >
                        <Input
                          value={temperature}
                          size="small"
                          onChange={handleInputTemperatureChange}
                          onBlur={handleInputTemperatureBlur}
                          inputProps={{
                            step: 0.01,
                            min: 0,
                            max: 1,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Stack>
                  <Slider
                    size="small"
                    value={temperature}
                    aria-label="Small"
                    step={0.01}
                    min={0}
                    max={1}
                    onChange={handleTemperatureChange}
                  />
                </Stack>
                <Stack spacing={0} direction="column">
                  <Stack spacing={1} direction="row">
                    <Grid item xs={8}>
                      <Tooltip
                        title={
                          "같은 표현이 반복되는 토큰에 패널티를 부여합니다. 패널티를 높게 설정할수록 언어모델이 다양한 표현을 생성할 확률이 높아집니다."
                        }
                        componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: "1.0rem",
                            },
                          },
                        }}
                      >
                        <Typography id="input-slider" gutterBottom>
                          Repeat Penalty
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                      >
                        <Input
                          value={repeatPenalty}
                          size="small"
                          onChange={handleInputRepeatPenaltyChange}
                          onBlur={handleInputRepeatPenaltyBlur}
                          inputProps={{
                            step: 1,
                            min: 0,
                            max: 10,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Stack>
                  <Slider
                    size="small"
                    value={repeatPenalty}
                    aria-label="Small"
                    step={1}
                    min={0}
                    max={10}
                    onChange={handleRepeatPenaltyChange}
                  />
                </Stack>
                <Tooltip
                  title={
                    "결과 생성을 중단하라는 신호가 될 문구를 설정합니다. 언어모델은 이 stop sequence 전까지의 내용만을 참고해서 토큰을 생성합니다."
                  }
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontSize: "1.0rem",
                      },
                    },
                  }}
                >
                  <TextField
                    id="txtStopSequences"
                    label="Stop Sequences(,로 구분)"
                    variant="outlined"
                    size="small"
                    value={stopSequences}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === "Enter")
                        setStopSequences(stopSequences + "↵");
                    }}
                    onChange={(e) => {
                      setStopSequences(e.target.value.replaceAll("\n", "↵"));
                    }}
                    fullWidth
                    required
                  />
                </Tooltip>
                <Tooltip
                  title={
                    "사용자 입력값 뒤에 붙일 텍스트를 설정합니다. 해당 텍스트를 기준으로 모델이 답변을 출력합니다. (예.↵결과:)"
                  }
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontSize: "1.0rem",
                      },
                    },
                  }}
                >
                  <TextField
                    id="txtInjectStartText"
                    label="Inject start text"
                    variant="outlined"
                    size="small"
                    value={injectStartText}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === "Enter")
                        setInjectStartText(injectStartText + "↵");
                    }}
                    onChange={(e) => {
                      setInjectStartText(e.target.value.replaceAll("\n", "↵"));
                    }}
                    fullWidth
                    required
                  />
                </Tooltip>
                <Tooltip
                  title={
                    "모델 출력값 뒤에 붙일 텍스트를 설정합니다. 해당 텍스트를 기준으로 사용자가 입력을 작성합니다. (예.↵입력:)"
                  }
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontSize: "1.0rem",
                      },
                    },
                  }}
                >
                  <TextField
                    id="txtInjectRestartText"
                    label="Inject restart text"
                    variant="outlined"
                    size="small"
                    value={injectRestartText}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === "Enter")
                        setInjectRestartText(injectRestartText + "↵");
                    }}
                    onChange={(e) => {
                      setInjectRestartText(
                        e.target.value.replaceAll("\n", "↵")
                      );
                    }}
                    fullWidth
                    required
                  />
                </Tooltip>
              </Stack>
            </Paper>
          </Grid>
          {/* Right */}
          {/* Right */}
          <Grid item xs={12} sm={5}>
            <Stack spacing={1} direction="column">
              {/* 검색조건 */}
              <Paper sx={{ p: 2 }} elevation={5}>
                <Stack spacing={1} direction="column">
                  <Stack spacing={1} direction="row">
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={fncDeleteModel}
                      >
                        삭제
                      </Button>
                    </Grid>
                    <Grid item xs={8}>
                      <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                      >
                        <Button variant="contained" onClick={fncSaveModel}>
                          저장
                        </Button>
                      </Stack>
                    </Grid>
                  </Stack>
                </Stack>
              </Paper>
              {/* 검색조건 */}
              <Paper sx={{ p: 2 }} elevation={5}>
                <Stack spacing={1} direction="column">
                  <TextField
                    id="txtPresetText"
                    label="Preset Text"
                    variant="outlined"
                    size="small"
                    value={presetText}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    onChange={(e) => {
                      setPresetText(e.target.value);
                    }}
                    multiline
                    rows={27.2}
                    fullWidth
                    required
                  />
                </Stack>
              </Paper>
            </Stack>
          </Grid>
          {/* Right */}
        </Grid>
      )}

      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </div>
  );
};

export default MngModel;
