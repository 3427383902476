import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";

import { HttpMainApi, PostQnaParam } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import "./QnaList.css";

interface propsType {
  userState: userState;
  reload: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "80%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "1.5rem",
  boxShadow: 24,
  pl: 2,
  pr: 2,
  pb: 3,
  pt: 1,
};

const mainApi = new HttpMainApi();

const QnaWrite = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const [saveOk, setSaveOk] = useState(false);

  const open = useCallback((open: boolean) => {
    setOpenModal(open);
  }, []);

  const fncCreateQuestion = useCallback(async () => {
    const param: PostQnaParam = {
      title: title,
      content: content,
      userState: props.userState,
    };

    setIsLoading(true);

    const res = await mainApi.post_question(param);
    if ("" + res.code === "200") {
      props.reload(true);
      setOpenModal(false);
    } else {
      toastRef.current?.toast("저장 중 에러가 발생했습니다.", "error", 3000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  }, [title, content, props]);

  useEffect(() => {
    if (title.trim() !== "" && content.trim() !== "") setSaveOk(true);
    else setSaveOk(false);
  }, [title, content]);

  useEffect(() => {
    setTitle("");
    setContent("");
  }, []);

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="column" spacing={0}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <IconButton
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                <CloseIcon className="color-red" />
              </IconButton>
            </Box>
            <Stack direction="column" spacing={2}>
              <TextField
                label="제목"
                variant="outlined"
                fullWidth
                value={title}
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter" }}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                required
              />
              <TextField
                label="내용"
                multiline
                rows={21}
                fullWidth
                value={content}
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter" }}
                onChange={(e) => {
                  setContent(e.target.value);
                }}
                required
              />
              <Box component={"div"}>
                <Stack direction="row" spacing={1}>
                  <ErrorIcon className="copy-info-caution-icon" />
                  <Typography color="inherit" className="copy-info-caution">
                    개인정보보호를 위해 문의 내용에는 휴대폰 번호 등 개인정보 입력을 지양하여 주시기 바랍니다.
                  </Typography>
                </Stack>
              </Box>
              <Divider sx={{ mb: "0.5rem !important" }} />
              <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <Button
                  variant="contained"
                  sx={{ borderRadius: "1.5rem", width: "20em" }}
                  onClick={() => {
                    fncCreateQuestion();
                  }}
                  disabled={!saveOk}
                >
                  문의하기
                </Button>
              </Box>
            </Stack>
          </Stack>
          <LoadingCircle loading={isLoading} />
          <Toast ref={toastRef} />
        </Box>
      </Modal>
    </>
  );
};

export default forwardRef(QnaWrite);
