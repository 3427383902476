import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import "./CopyWriter.css";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: "450px",
    minWidth: "450px",
    borderRadius: "8px",
    padding: "1rem 1rem",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.25)",
  },
}));

const TooltipKeyword = () => {
  return (
    <>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit" className="copy-info-help">
              다양한 키워드를 입력해주세요.
            </Typography>
            <br />
            <Typography color="inherit" className="copy-info-help">
              ex1 : 시즌 오프, 고백하는 날, 입학식
            </Typography>
            <Typography color="inherit" className="copy-info-help">
              ex2 : 무지개, 구름, 불꽃, 바다, 성공, 하트
            </Typography>
            <br />
            <Typography color="inherit" className="copy-info-help">
              TIP) 키워드에 따라 제목과 본문 내용이 다양해집니다.
            </Typography>
            <Typography color="inherit" className="copy-info-help">
              브랜드 : 나이키, 키워드 : 구름
            </Typography>
            <Typography color="inherit" className="copy-info-help">
              제목 : 모든 순간이 / 하늘 위를 걷는 것처럼 / 가볍다.
            </Typography>
          </React.Fragment>
        }
        placement="right"
      >
        <HelpOutlineIcon className="copy-info color-black" />
      </HtmlTooltip>
    </>
  );
};

export default TooltipKeyword;
