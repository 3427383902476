import { ApiBase } from "./api-base";
import { userState, inputList } from "../interface/MainInterface";
import { appConfig } from "../config/Config";

//로그인 임시

export interface GetLoginParam {
  userId: string;
  password: string;
}

export interface CheckLoginParam {
  userId: string;
}
export interface GetchpwdParam {
  userId: string;
  password: string;
}

export interface GetDashboardListParam {
  userState: userState;
}

// Lewis Start
export interface GetHistoryListParam {
  userState: userState;
  dataText: string;
  fromDateText: string;
  toDateText: string;
}

export interface GetHistoryInfoParam {
  pk: string;
  range: string;
}

export interface GetGtpTotalParam {
  userState: userState;
  dataText: string;
  joinText: string;
  multipleText: string;
  make_num: string;
}

export interface GetGtpTitleParam {
  userId: string;
  dataText: string;
  joinText: string;
  multipleText: string;
}

export interface GetGtpContentParam {
  userState: userState;
  pk: string;
  range: string;
  dataText: string;
  joinText: string;
  titleText: string;
  multipleText: string;
}

export interface GetBookMarListParam {
  userId: string;
}

export interface GetMyBookMarkListParam {
  userId: string;
  dataText: string;
}

export interface PutBookMarkParam {
  pk: string;
  range: string;
  category: string;
  subRange: string;
  userState: userState;
  inputList: inputList;
}

export interface GetBookMarkInfoParam {
  userState: userState;
  range: string;
}

export interface GetLikeInfoParam {
  userState: userState;
  range: string;
}

export interface PutLikeInfoParam {
  pk: string;
  range: string;
  category: string;
  subRange: string;
  userState: userState;
  inputList: inputList;
}

export interface GetMyLikeListParam {
  userId: string;
  dataText: string;
  fromDateText: string;
  toDateText: string;
}

export interface GetUnLikeInfoParam {
  userState: userState;
  range: string;
}

export interface PutUnLikeInfoParam {
  pk: string;
  range: string;
  category: string;
  subRange: string;
  userState: userState;
  inputList: inputList;
}

export interface GetMyHistoryListParam {
  userId: string;
  dataText: string;
  fromDateText: string;
  toDateText: string;
}

// qna
export interface GetQnACntParam {
  userState: userState;
}

export interface GetQnAListParam {
  userState: userState;
  page: number;
}

export interface PostQuestion {
  title: string;
  content: string;
  userState: userState;
}

export interface RePostQuestion {
  seq: string;
  title: string;
  content: string;
  userState: userState;
}

export interface PostAnswer {
  content: string;
  oriseq: string;
  qseq: string;
  userState: userState;
}

export interface GetQnAListByCompanyParam {
  search_date_from: string;
  search_date_to: string;
  user_state: userState;
  page: number;
  answer_filter: string;
}

export interface GetQnADetailByCompanyParam {
  user_state: userState;
  qseq: string;
}

// gpt
export interface MakeGptContent {
  type: string;
  idx: string;
  teamType: string;
  userState: userState;
  brand_text: string;
  add_keyword: string;
  add_title: string;
  pk: string;
  range: string;
}

export interface SetReplaceList {
  replace_list: any;
}

export interface GetModelSelectList {
  idx: string;
}

export interface SetModelSelectList {
  model_select_list: any;
}

export interface SetModelInfo {
  model_info: {
    aatag: string;
    api_key: string;
    api_key_primary_val: string;
    gubun: string;
    host: string;
    post_url: string;
    preset_text: string;
    request_data: {
      includeAiFilters: string;
      includeProbs: string;
      includeTokens: string;
      maxTokens: number;
      repeatPenalty: number;
      restart: string;
      start: string;
      stopBefore: string;
      temperature: string;
      text: string;
      topK: number;
      topP: number;
    };
    request_id: string;
  };
}

export interface DelModelInfo {
  request_id: string;
  gubun: string;
}

export interface GetDashboardParam {
  userState: userState;
  fromDateText: string;
  toDateText: string;
  readDept: [];
}

export interface GetCopyListByCompanyParam {
  search_date_from: string;
  search_date_to: string;
  user_state: userState;
  page: number;
}

export interface GetCopyHistoryListByCompanyParam {
  search_date_from: string;
  search_date_to: string;
  user_state: userState;
  limit: number;
  last_key: string;
}

export interface DeleteCopyByCompanyParam {
  user_state: userState;
  delete_ids: [];
}

// 각종통계
// 일별 API 호출현황
export interface GetDailyCallApiListParam {
  search_date_from: string;
  search_date_to: string;
  user_state: userState;
}

// 일별 컨텐츠 생성현황
export interface GetDailyMakeContentListParam {
  search_date_from: string;
  search_date_to: string;
  user_state: userState;
}

// 부서별 토큰 현황
export interface GetTokenStatusByDeptListParam {
  search_date_from: string;
  search_date_to: string;
  user_state: userState;
}

// Lewis END

export class HttpAdminMainApi extends ApiBase {
  onApiName(): string {
    return "AdminMainApi";
  }

  onUrlPath(): string {
    if (window.location.hostname === "localhost") return appConfig.testApiUrl;
    else return appConfig.mainApiUrl;
  }

  // 로그인
  public async get_user_info(params: GetLoginParam) {
    const requestBody = {
      command: "get_user_info",
      userId: params.userId,
      password: params.password,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  // 로그인 DDB 접속해서 UUID 가져오기
  public async get_user_info_uuid(params: string) {
    const requestBody = {
      command: "get_user_info_uuid",
      uuid: params,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  // RDB랑 통신 확인하는 코드
  public async check_user_rds(keyId: string) {
    const requestBody = {
      command: "check_user_rds",
      userId: keyId,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  // 리다이렉팅 코드 추가
  public async check_company_url(companyName: string) {
    const requestBody = {
      command: "check_company_url",
      company: companyName,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_update_pwd(params: GetchpwdParam) {
    const requestBody = {
      command: "get_update_pwd",
      userId: params.userId,
      password: params.password,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // history 검색
  //--------------------------------------------------------------

  public async get_history_list(params: GetHistoryListParam) {
    const requestBody = {
      command: "get_history_list",
      userState: params.userState,
      dataText: params.dataText,
      fromDateText: params.fromDateText,
      toDateText: params.toDateText,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_history_info(params: GetHistoryInfoParam) {
    const requestBody = {
      command: "get_history_info",
      pk: params.pk,
      range: params.range,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // Gpt 검색
  //--------------------------------------------------------------
  public async get_gpt_content_info(params: GetGtpContentParam) {
    const requestBody = {
      command: "get_gpt_content_info",
      userState: params.userState,
      pk: params.pk,
      range: params.range,
      dataText: params.dataText,
      joinText: params.joinText,
      titleText: params.titleText,
      multipleText: params.multipleText,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // bookmark 관리
  //--------------------------------------------------------------
  public async get_bookmark_list(params: GetBookMarListParam) {
    const requestBody = {
      command: "get_bookmark_list",
      userId: params.userId,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_my_bookmark_list(params: GetMyBookMarkListParam) {
    const requestBody = {
      command: "get_my_bookmark_list",
      userId: params.userId,
      dataText: params.dataText,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_bookmark_info(params: GetBookMarkInfoParam) {
    const requestBody = {
      command: "get_bookmark_info",
      userState: params.userState,
      range: params.range,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_bookmark_info(params: PutBookMarkParam) {
    const requestBody = {
      command: "put_bookmark_info",
      pk: params.pk,
      range: params.range,
      category: params.category,
      subRange: params.subRange,
      userState: params.userState,
      inputList: params.inputList,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // like 관리
  //--------------------------------------------------------------
  public async get_my_like_list(params: GetMyLikeListParam) {
    const requestBody = {
      command: "get_my_like_list",
      userId: params.userId,
      dataText: params.dataText,
      fromDateText: params.fromDateText,
      toDateText: params.toDateText,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_like_info(params: GetLikeInfoParam) {
    const requestBody = {
      command: "get_like_info",
      userState: params.userState,
      range: params.range,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_like(params: PutLikeInfoParam) {
    const requestBody = {
      command: "put_like",
      pk: params.pk,
      range: params.range,
      category: params.category,
      subRange: params.subRange,
      userState: params.userState,
      inputList: params.inputList,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // unlike 관리
  //--------------------------------------------------------------
  public async get_unlike_info(params: GetUnLikeInfoParam) {
    const requestBody = {
      command: "get_unlike_info",
      userState: params.userState,
      range: params.range,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_unlike(params: PutUnLikeInfoParam) {
    const requestBody = {
      command: "put_unlike",
      pk: params.pk,
      range: params.range,
      category: params.category,
      subRange: params.subRange,
      userState: params.userState,
      inputList: params.inputList,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // mypage 관리
  //--------------------------------------------------------------
  public async get_my_history_list(params: GetMyHistoryListParam) {
    const requestBody = {
      command: "get_my_history_list",
      userId: params.userId,
      dataText: params.dataText,
      fromDateText: params.fromDateText,
      toDateText: params.toDateText,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // qna 관리
  //--------------------------------------------------------------
  public async get_my_qna_cnt(params: GetQnACntParam) {
    const requestBody = {
      command: "get_my_qna_cnt",
      userState: params.userState,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_my_qna_list(params: GetQnAListParam) {
    const requestBody = {
      command: "get_my_qna_list",
      userState: params.userState,
      paging: params.page,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async post_question(params: PostQuestion) {
    const requestBody = {
      command: "post_question",
      title: params.title,
      content: params.content,
      userState: params.userState,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async repost_question(params: RePostQuestion) {
    const requestBody = {
      command: "repost_question",
      title: params.title,
      oriseq: params.seq,
      content: params.content,
      userState: params.userState,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_my_question_detail(qseq: string) {
    const requestBody = {
      command: "get_my_question_detail",
      seq: qseq,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_my_answer_detail(qseq: string) {
    const requestBody = {
      command: "get_my_answer_detail",
      seq: qseq,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  // 답변하기
  public async post_qna_answer(params: PostAnswer) {
    const requestBody = {
      command: "post_qna_answer",
      content: params.content,
      oriseq: params.oriseq,
      qseq: params.qseq,
      userState: params.userState,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_admin_qna_list(param: GetQnAListByCompanyParam) {
    const requestBody = {
      command: "get_admin_qna_list",
      search_date_from: param.search_date_from,
      search_date_to: param.search_date_to,
      user_state: param.user_state,
      page: param.page,
      answer_filter: param.answer_filter,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  // qseq에 대한 전체 답변 가져오기
  public async get_qna_total_detail(param: GetQnADetailByCompanyParam) {
    const requestBody = {
      command: "get_qna_total_detail",
      qseq: param.qseq,
      user_state: param.user_state,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // Gpt 생성
  //--------------------------------------------------------------
  public async make_gpt_copy(params: MakeGptContent) {
    try {
      const requestBody = {
        command: "make_gpt_copy",
        type: params.type,
        idx: params.idx,
        teamType: params.teamType,
        userState: params.userState,
        brand_text: params.brand_text,
        add_keyword: params.add_keyword,
        add_title: params.add_title,
        pk: params.pk,
        range: params.range,
      };

      const response = this.requestPost(requestBody);
      return response;
    } catch (error) {
      return { code: "500", response: { error_msg: "apiTimeout" } };
    }
  }

  public async poll_gpt_info(params: MakeGptContent) {
    try {
      const requestBody = {
        command: "poll_gpt_info",
        type: params.type,
        idx: params.idx,
        userState: params.userState,
        brand_text: params.brand_text,
        add_keyword: params.add_keyword,
        add_title: params.add_title,
        pk: params.pk,
        range: params.range,
      };

      const response = this.requestPost(requestBody);
      return response;
    } catch (error) {
      return { code: "500", response: { error_msg: "apiTimeout" } };
    }
  }

  //--------------------------------------------------------------
  // 설정 관리
  //--------------------------------------------------------------
  public async get_replace_list() {
    const requestBody = {
      command: "get_replace_list",
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async set_replace_list(params: SetReplaceList) {
    const requestBody = {
      command: "set_replace_list",
      replace_list: params.replace_list,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_model_list() {
    const requestBody = {
      command: "get_model_list",
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_model_select_list(params: GetModelSelectList) {
    const requestBody = {
      command: "get_model_select_list",
      idx: params.idx,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async set_model_select_list(params: SetModelSelectList) {
    const requestBody = {
      command: "set_model_select_list",
      model_select_list: params.model_select_list,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async set_model_info(params: SetModelInfo) {
    const requestBody = {
      command: "set_model_info",
      model_info: params.model_info,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async del_model_info(params: DelModelInfo) {
    const requestBody = {
      command: "del_model_info",
      request_id: params.request_id,
      gubun: params.gubun,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_dashboard(params: GetDashboardParam) {
    const requestBody = {
      command: "get_dashboard",
      userState: params.userState,
      fromDateText: params.fromDateText,
      toDateText: params.toDateText,
      readDept: params.readDept,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // Copy 관리 모듈
  //--------------------------------------------------------------
  // 생성한 본문 카피 가져오기
  public async get_admin_copy_content_list(param: GetCopyListByCompanyParam) {
    const requestBody = {
      command: "get_admin_copy_content_list",
      fromDateText: param.search_date_from,
      toDateText: param.search_date_to,
      userState: param.user_state,
      page: param.page,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  // 생성한 타이틀 카피 가져오기
  public async get_admin_copy_title_list(param: GetCopyListByCompanyParam) {
    const requestBody = {
      command: "get_admin_copy_title_list",
      fromDateText: param.search_date_from,
      toDateText: param.search_date_to,
      userState: param.user_state,
      page: param.page,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_admin_copy_history_list(
    param: GetCopyHistoryListByCompanyParam
  ) {
    const requestBody = {
      command: "get_admin_copy_history_list",
      fromDateText: param.search_date_from,
      toDateText: param.search_date_to,
      userState: param.user_state,
      limit: param.limit,
      lastKey: param.last_key,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_admin_copy_title_detail_list(param: any) {
    const requestBody = {
      command: "get_admin_copy_title_detail_list",
      title_history_list: param.title_history_list,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_admin_copy_content_detail_list(param: any) {
    const requestBody = {
      command: "get_admin_copy_content_detail_list",
      content_history_list: param.content_history_list,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  // title 삭제용
  public async delete_admin_copy_titles(param: DeleteCopyByCompanyParam) {
    const requestBody = {
      command: "delete_admin_copy_titles",
      userState: param.user_state,
      deleteIds: param.delete_ids,
    };
    const response = this.requestPost(requestBody);
    return response;
  }
  // content 삭제용
  public async delete_admin_copy_contents(param: DeleteCopyByCompanyParam) {
    const requestBody = {
      command: "delete_admin_copy_contents",
      userState: param.user_state,
      deleteIds: param.delete_ids,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // 각종통계
  //--------------------------------------------------------------
  public async get_daily_call_api_list(param: GetDailyCallApiListParam) {
    const requestBody = {
      command: "get_daily_call_api_list",
      search_date_from: param.search_date_from,
      search_date_to: param.search_date_to,
      user_state: param.user_state,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_daily_make_content_list(
    param: GetDailyMakeContentListParam
  ) {
    const requestBody = {
      command: "get_daily_make_content_list",
      search_date_from: param.search_date_from,
      search_date_to: param.search_date_to,
      user_state: param.user_state,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_token_status_by_dept_list(
    param: GetTokenStatusByDeptListParam
  ) {
    const requestBody = {
      command: "get_token_status_by_dept_list",
      search_date_from: param.search_date_from,
      search_date_to: param.search_date_to,
      user_state: param.user_state,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // 공통 모듈
  //--------------------------------------------------------------
  public async get_presigned_url(key: string) {
    const requestBody = {
      command: "get_presigned_url",
      key: key,
    };
    const response = this.requestPost(requestBody);
    return response;
  }
}
