import { useCallback, useState } from "react";

import Tooltip from "@mui/material/Tooltip";

import { userState } from "../../interface/MainInterface";
import { HttpMainApi } from "../../interface/main-api";
import { appConfig } from "../../config/Config";

interface propsType {
  userState: userState;
  copyText: string;
}

const mainApi = new HttpMainApi();

const Copy = (props: propsType) => {
  const [className, setClassName] = useState("fas fa-icon fa-clone");
  const [copyContent, setCopyContent] = useState("문장 복사");
  const setAction = useCallback(async () => {
    try {
      setCopyContent("복사 완료");
      setTimeout(() => {
        setCopyContent("문장 복사");
      }, 2000);
      await navigator.clipboard.writeText(props.copyText);
    } catch (error) {}
  }, [props.copyText]);

  return (
    <Tooltip
      title={copyContent}
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "common.black",
            "& .MuiTooltip-arrow": {
              color: "common.black",
            },
            fontSize: "0.8rem",
          },
        },
      }}
      arrow
    >
      <i className={className} onClick={setAction} />
    </Tooltip>
  );
};

export default Copy;
