import { useState } from "react";

import { userState, inputList } from "../../interface/MainInterface";
import { HttpMainApi, GetUnLikeInfoParam, PutUnLikeInfoParam } from "../../interface/main-api";
import { appConfig } from "../../config/Config";

interface propsType {
  pk: string;
  range: string;
  category: string;
  subRange: string;
  userState: userState;
  inputList: inputList;
  getHistoryInfo: () => void;
}

const mainApi = new HttpMainApi();

const UnLike = (props: propsType) => {
  const [className, setClassName] = useState("fas fa-icon fa-thumbs-down");
  const setClickUnLike = async () => {
    const param: PutUnLikeInfoParam = {
      pk: props.pk,
      range: props.range,
      category: props.category,
      subRange: props.subRange,
      userState: props.userState,
      inputList: props.inputList,
    };
    const res = await mainApi.put_unlike(param);
    if ("" + res.code === "200") {
      res.response.result === "Y"
        ? setClassName("fas fa-icon fa-thumbs-down active")
        : setClassName("fas fa-icon fa-thumbs-down");
      props.getHistoryInfo();
    } else {
      console.error("setClickUnLike ERROR : ", res.response.error_msg);
    }
  };

  const get_unlike_info = async () => {
    const param: GetUnLikeInfoParam = {
      userState: props.userState,
      range: props.range,
    };
    const res = await mainApi.get_unlike_info(param);
    if ("" + res.code === "200") {
      res.response.result === "Y"
        ? setClassName("fas fa-icon fa-thumbs-down active")
        : setClassName("fas fa-icon fa-thumbs-down");
    } else {
      console.error("get_unlike_info ERROR : ", res.response.error_msg);
    }
  };
  return <i className={className} onClick={setClickUnLike} />;
};

export default UnLike;
