/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useCallback, useRef } from "react";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
  GridRowSelectionModel,
  useGridApiRef,
} from "@mui/x-data-grid";

import { HttpMainApi, SetReplaceList } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import { appConfig } from "../../config/Config";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import AskPassword from "./AskPassword";

import "./MngReplace.css";

interface propsType {
  userState: userState;
}

interface IGridCols {
  pk: string;
  range: string;
  target: string;
  replace: string;
}

const MngReplace = (props: propsType) => {
  const mainApi = new HttpMainApi();

  const gridApi = useGridApiRef();
  const toastRef: any = useRef();

  const [isLoading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState<GridRowSelectionModel>([]);
  const [dataRows, setDataRows] = useState<IGridCols[]>([]);
  const dataColumn: GridColDef[] = [
    { field: "range", headerName: "순번", headerAlign: "center", align: "center", width: 60 },
    { field: "target", headerName: "대상", headerAlign: "center", align: "center", editable: true, flex: 1 },
    { field: "replace", headerName: "변경", headerAlign: "center", align: "center", editable: true, flex: 1 },
    {
      field: "pk",
      headerName: "",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <Stack spacing={0} direction="row">
              {param.row.range > 1 ? (
                <IconButton onClick={() => fncSort("pre", param.row.range)}>
                  <ArrowUpwardIcon className="color-red" />
                </IconButton>
              ) : (
                <IconButton disabled>
                  <ArrowUpwardIcon className="color-red" />
                </IconButton>
              )}
              {param.row.range === dataRows.length + "" ? (
                <IconButton disabled>
                  <ArrowDownwardIcon className="color-red" />
                </IconButton>
              ) : (
                <IconButton onClick={() => fncSort("next", param.row.range)}>
                  <ArrowDownwardIcon className="color-red" />
                </IconButton>
              )}
              <IconButton onClick={() => fncRemove(param.row.range)}>
                <RemoveCircleOutlineIcon className="color-red" />
              </IconButton>
            </Stack>
          </>
        );
      },
    },
  ];

  // 조회
  const fncGetList = async () => {
    setLoading(true);
    const res = await mainApi.get_replace_list();
    if ("" + res.code === "200") {
      setDataRows(res.response.replace_list);
    } else {
      toastRef.current?.toast("조회 중 에러가 발생했습니다.", "error", 3000, { vertical: "top", horizontal: "center" });
      console.error("fncGetList ERROR : ", res.response.error_msg);
    }
    setLoading(false);
  };

  // 저장
  const fncSaveList = async () => {
    if (!window.confirm("자료를 저장하시겠습니까?")) return;
    const param: SetReplaceList = {
      replace_list: dataRows,
    };
    setLoading(true);
    const res = await mainApi.set_replace_list(param);
    if ("" + res.code === "200") {
      toastRef.current?.toast("저장을 완료했습니다.", "success", 3000, { vertical: "top", horizontal: "center" });
      fncGetList();
    } else {
      toastRef.current?.toast("저장 중 에러가 발생했습니다.", "error", 3000, { vertical: "top", horizontal: "center" });
      console.error("fncSaveList ERROR : ", res.response.error_msg);
    }
    setLoading(false);
  };

  // 신규 행 추가
  const fncAddList = async () => {
    let tmpRow: any = [...dataRows];
    tmpRow.push({
      pk: "REPLACE#" + appConfig.projectName,
      range: (dataRows.length + 1).toString(),
      range_num: dataRows.length + 1,
      target: "",
      replace: "",
    });
    setDataRows(tmpRow);
    setTimeout(() => {
      gridApi.current.scrollToIndexes({ rowIndex: dataRows.length, colIndex: 1 }); // 마지막 행으로 스크롤 이동
      setSelectedRow([(dataRows.length + 1).toString()]); // 현재 선택된 행 지정
    }, 100);
  };

  // 행 정렬 (위/아래 이동)
  const fncSort = (flag: string, range: string) => {
    let tmpRow: any = [...dataRows];
    let selectRow: string = range;

    if (flag === "pre") {
      const nowRow: number = parseInt(range) - 1;
      tmpRow[nowRow].range = nowRow.toString();
      tmpRow[nowRow].range_num = nowRow;
      tmpRow[nowRow - 1].range = range;
      tmpRow[nowRow - 1].range_num = parseInt(range);
      selectRow = nowRow.toString();
    }

    if (flag === "next") {
      const nowRow: number = parseInt(range) - 1;
      tmpRow[nowRow].range = (parseInt(range) + 1).toString();
      tmpRow[nowRow].range_num = parseInt(range) + 1;
      tmpRow[nowRow + 1].range = range;
      tmpRow[nowRow + 1].range_num = parseInt(range);
      selectRow = (parseInt(range) + 1).toString();
    }

    tmpRow.sort(function (a: any, b: any) {
      if (a.range_num > b.range_num) return 1;
      if (a.range_num < b.range_num) return -1;
      return 0;
    });
    setDataRows([]);
    setDataRows(tmpRow);

    setTimeout(() => {
      setSelectedRow([selectRow]); // 변경된 행을 선택된 행으로
    }, 100);
  };

  // 행 삭제
  const fncRemove = (range: string) => {
    let tmpRow: any = [...dataRows];
    let removeRow: number = 0;

    for (let i = 0; i < tmpRow.length; i++) {
      if (tmpRow[i].range === range) {
        tmpRow.splice(i, 1);
        removeRow = i;
        i--;
      }
    }

    for (let i = 0; i < tmpRow.length; i++) {
      if (i >= removeRow) {
        tmpRow[i].range = (parseInt(tmpRow[i].range) - 1).toString();
        tmpRow[i].range_num = parseInt(tmpRow[i].range);
      }
    }

    setDataRows([]);
    setDataRows(tmpRow);

    setTimeout(() => {
      setSelectedRow([]); // 변경된 행을 선택된 행으로
    }, 100);
  };

  // event Row Edit end
  const evtEditEnd = async (newRow: GridRowModel) => {
    let tmpRow: any = [...dataRows];
    tmpRow[parseInt(newRow.range) - 1] = newRow;
    setDataRows(tmpRow);
    return newRow;
  };

  // event Error when editting row
  const handleProcessRowUpdateError = useCallback((error: Error) => {
    console.error("Row Update Error : ", error.message);
  }, []);

  // 페이지 로딩시 실행
  useEffect(() => {
    fncGetList();
  }, []);

  // 로그인 체크
  const [view, setView] = useState(false);
  useEffect(() => {
    if (!props.userState.id) {
      window.location.href = "/login";
    }
    if (props.userState.userType !== "1") {
      window.location.href = "/";
    } else {
      setView(true);
    }
  }, [props.userState]);

  const [passwordConfirm, setPasswordConfirm] = useState(false);
  const pwdCallback = (result: boolean) => {
    setPasswordConfirm(result);
  };

  return (
    <div>
      {!passwordConfirm ? (
        <AskPassword callBack={pwdCallback} />
      ) : (
        <Grid container spacing={1} sx={{ paddingX: 1 }} component="div">
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              치환문자관리
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }} elevation={5}>
              <Stack spacing={1} direction="column">
                <Stack spacing={1} direction="row">
                  <Grid item xs={8}></Grid>
                  <Grid item xs={4}>
                    <Stack spacing={2} direction="row" justifyContent="flex-end">
                      <Button variant="contained" onClick={fncGetList}>
                        조회
                      </Button>
                      <Button variant="contained" onClick={fncAddList}>
                        추가
                      </Button>
                      <Button variant="contained" onClick={fncSaveList}>
                        저장
                      </Button>
                    </Stack>
                  </Grid>
                </Stack>
                <div className="datatable-replace">
                  <DataGrid
                    apiRef={gridApi}
                    rows={dataRows}
                    columns={dataColumn}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 100,
                        },
                      },
                      sorting: {
                        sortModel: [
                          {
                            field: "range_num",
                            sort: "asc",
                          },
                        ],
                      },
                    }}
                    pageSizeOptions={[3, 10, 20, 50, 100]}
                    getRowId={(row) => row.range}
                    processRowUpdate={evtEditEnd}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    rowSelectionModel={selectedRow}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setSelectedRow(newRowSelectionModel);
                    }}
                  />
                </div>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      )}

      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </div>
  );
};

export default MngReplace;
