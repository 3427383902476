const isLocalhost =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1" ||
  window.location.hostname.startsWith("dx7kzffhzuplw");

const devConfig = {
  projectName: "lewis03-v1-Dev",
  systemNameEng: "GlingDev",
  systemName: "GlingDev",
  systemNameExam: "Gling(Dev)이",
  systemUrl: "https://greenfood.ai-lewis.com",
  mainApiUrl:
    "https://q7ziph9xk7.execute-api.ap-northeast-2.amazonaws.com/dev/adminMain",
  testApiUrl:
    "https://q7ziph9xk7.execute-api.ap-northeast-2.amazonaws.com/dev/adminMain",
  RecommendApiUrl:
    "https://q44g6b2tyvko7tinfpfhqcmxny0vogdh.lambda-url.ap-northeast-2.on.aws",
  NaverKeywordApiUrl:
    "https://fiiwbhjsghq47ffpluxkkjfuv40uvamp.lambda-url.ap-northeast-2.on.aws",
  sessionName: "lewis03-v1-adminss", // 사이트마다 달라야 함
  rememberidSSName: "lewis03-v1-rmbrid", // 사이트마다 달라야 함
  showTerms: true, // true : 사용자로그인, false : H-ART 로그인
  seed: "22333",
  sessionTimeout: 1000 * 60 * 60 * 3, // 180분
  qnaInfoSSName: "lewis03-v1-QnaInfo",
  defaultCompany: {
    name: "현대그린푸드",
    url: "http://dx7kzffhzuplw.cloudfront.net/",
  },
  companiesProd: [
    {
      name: "현대백화점",
      url: "https://dh02c1lkr9h0r.cloudfront.net/",
    },
    {
      name: "현대그린푸드",
      url: "https://dx7kzffhzuplw.cloudfront.net",
    },
  ],
  companiesDev: [
    {
      name: "현대그린푸드",
      url: "http://localhost:3000",
    },
  ],
}

const prodConfig = {
  projectName: "lewis03-v1-Prod",
  systemNameEng: "Gling",
  systemName: "Gling",
  systemNameExam: "Gling이",
  systemUrl: "https://greenfood.ai-lewis.com",
  mainApiUrl:
    "https://lsane93lwj.execute-api.ap-northeast-2.amazonaws.com/prod/adminMain",
  testApiUrl:
    "https://lsane93lwj.execute-api.ap-northeast-2.amazonaws.com/prod/adminMain",
  RecommendApiUrl:
    "https://q44g6b2tyvko7tinfpfhqcmxny0vogdh.lambda-url.ap-northeast-2.on.aws",
  NaverKeywordApiUrl:
    "https://fiiwbhjsghq47ffpluxkkjfuv40uvamp.lambda-url.ap-northeast-2.on.aws",
  sessionName: "lewis03-v1-adminss", // 사이트마다 달라야 함
  rememberidSSName: "lewis03-v1-rmbrid", // 사이트마다 달라야 함
  showTerms: true, // true : 사용자로그인, false : H-ART 로그인
  seed: "22333",
  sessionTimeout: 1000 * 60 * 60 * 3, // 180분
  qnaInfoSSName: "lewis03-v1-QnaInfo",
  defaultCompany: {
    name: "현대그린푸드",
    url: "https://greenfood.ai-lewis.com/",
  },
  companiesProd: [
    {
      name: "현대백화점",
      url: "https://ehyundai.ai-lewis.com/",
    },
    {
      name: "현대홈쇼핑",
      url: "https://hmall.ai-lewis.com",
    },
    {
      name: "현대그린푸드",
      url: "https://greenfood.ai-lewis.com",
    },
  ],
  companiesDev: [
    {
      name: "현대백화점",
      url: "http://localhost:3000",
    },
    {
      name: "현대홈쇼핑",
      url: "https://hmall.ai-lewis.com",
    },
    {
      name: "현대그린푸드",
      url: "https://greenfood.ai-lewis.com",
    },
  ],
};

export const appConfig = isLocalhost ? devConfig : prodConfig;
