import { useEffect, useState } from "react";
import { useInterval } from "./UseInterval";
import Backdrop from "@mui/material/Backdrop";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface propsType {
  loading: boolean;
  loadingTexts: any;
  interval: number;
}

let typingIdx = 0;
let liIndex = 0;
let typingTxt = "";

const LoadingCopy = (props: propsType) => {
  let liLength = props.loadingTexts.length;
  const [loadingText, setLoadingText] = useState<any>([]);
  const [viewText, setViewText] = useState("");

  useInterval(
    () => {
      if (props.loading && loadingText.length > 0) {
        // 처음 문자열을 읽어오기
        let nowText = loadingText[liIndex];
        typingTxt = nowText.split("");
        if (typingIdx < typingTxt.length) {
          // 타이핑될 텍스트 길이만큼 반복
          try {
            let tempText = viewText;
            tempText = viewText + typingTxt[typingIdx];
            setViewText(tempText);
            // document.getElementById("loadingText")?.append(typingTxt[typingIdx]); // 한글자씩 이어준다.
            typingIdx++;
          } catch (error) {}
        } else {
          //다음문장으로.. 마지막문장이면 다시 첫번째 문장으로
          if (liIndex >= liLength - 1) {
            liIndex = 0;
          } else {
            liIndex++;
          }
          typingIdx = 0;
          try {
            setViewText("");
          } catch (error) {}
        }
      } else {
        setViewText("");
      }
    },
    props.loading ? props.interval : null
  );

  useEffect(() => {
    if (props.loading && loadingText !== props.loadingTexts) {
      setViewText("");
    }
    typingIdx = 0;
    liIndex = 0;
    typingTxt = "";
    setLoadingText(props.loadingTexts);
  }, [props.loadingTexts, props.loading, loadingText]);

  return (
    <>
      <Backdrop
        sx={{ backgroundColor: "rgba(0, 0, 0, 0.8)", color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
        open={props.loading}
      >
        <Stack direction="column">
          <div className="load-cont">
            <div className="spinner-wrap">
              <div className="spinner">
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
          </div>
          <Typography className="typing">{viewText}</Typography>
        </Stack>
      </Backdrop>
    </>
  );
};

export default LoadingCopy;
