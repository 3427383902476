import * as React from "react";
import { useNavigate } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";

import * as AuthMain from "../auth/AuthMain";
import LeftMenu from "./LeftMenu";
import { userState } from "../interface/MainInterface";
import * as QnaPagenation from "../page/qna/QnaPageNation";

interface propsType {
  userState: userState;
  open: boolean;
  winSize: string;
  selectedMenu: string;
  headerLarge: boolean;
  drawerWidth: number;
  largeDrawerWidth: number;
  handleDrawer: any;
  menuOver: any;
  menuOut: any;
}

const Header = (props: propsType) => {
  let navigate = useNavigate();

  const handleDrawer = () => {
    props.handleDrawer();
  };

  const signOut = () => {
    if (!window.confirm("로그아웃 하시겠습니까?")) return;
    const result = AuthMain.signOut();
    if (result) window.location.href = "/login";
  };

  const move = React.useCallback(
    (loc: string) => {
      QnaPagenation.clear();
      if (props.winSize === "xs") {
        props.handleDrawer();
      }
      navigate(loc);
    },
    [navigate, props]
  );

  return (
    <Box sx={{ flexGrow: 1, position: "sticky", top: "0", zIndex: "700" }}>
      <AppBar position="fixed" sx={{ backgroundColor: "#f8f9fa", boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)" }}>
        <Drawer
          sx={{
            width: props.drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: props.drawerWidth,
              boxSizing: "border-box",
              overflow: "hidden",
              backgroundColor: "#212121",
              color: "#ffffff",
              paddingLeft: "6px",
              transition: "width 0.5s",
            },
          }}
          anchor="left"
          open={props.open}
          variant={"persistent"}
          onMouseOver={() => {
            if (props.winSize === "xs") {
              props.menuOver();
            } else {
              if (!props.headerLarge) props.menuOver();
            }
          }}
          onMouseOut={() => {
            if (props.winSize === "xs") {
              props.menuOut();
            } else {
              if (!props.headerLarge) props.menuOut();
            }
          }}
          onClose={() => {
            props.handleDrawer();
          }}
          className="main-sidebar sidebar-dark-primary"
        >
          <LeftMenu
            userState={props.userState}
            drawerWidth={props.drawerWidth}
            largeDrawerWidth={props.largeDrawerWidth}
            selectedMenu={props.selectedMenu}
            move={move}
          />
        </Drawer>
        <Toolbar
          sx={{
            ml: `${props.drawerWidth}px`,
            minHeight: "56px !important",
            display: "flex",
            justifyContent: "space-between",
            pr: "0px !important",
            transition: "margin 0.5s",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            sx={{ mr: 2, color: "#212121", display: "flex" }}
          >
            <CardMedia
              component="img"
              image={"/images/icon-nav-btn.png"}
              sx={{ width: "20px", height: "20px", objectFit: "contain" }}
            />
          </IconButton>
          <Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={signOut}
              edge="start"
              sx={{ mr: 2, color: "#212121", display: "flex" }}
            >
              <CardMedia
                component="img"
                image={"/images/icon-logout.png"}
                sx={{ width: "20px", height: "20px", objectFit: "contain" }}
              />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
