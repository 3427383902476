/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useCallback, useRef } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
  GridRowSelectionModel,
  useGridApiRef,
} from "@mui/x-data-grid";

import { HttpMainApi, GetModelSelectList, SetModelSelectList } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import AskPassword from "./AskPassword";

import "./ModelSelect.css";

interface propsType {
  userState: userState;
}

interface IGridCols {
  pk: string;
  sk: string;
  model_name: string;
}

const mainApi = new HttpMainApi();

const ModelSelect = (props: propsType) => {
  const toastRef: any = useRef();
  const gridApi = useGridApiRef();
  const [isLoading, setLoading] = useState(false);

  const [openAddForm, setOpenAddForm] = useState(false);
  const [searchModelIdx, setSearchModelIdx] = useState("");
  const [modelIdx, setModelIdx] = useState("");
  const [modelType, setModelType] = useState("");
  const [modelName, setModelName] = useState("");
  const [models, setModels] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<GridRowSelectionModel>([]);
  const [dataRows, setDataRows] = useState<IGridCols[]>([]);
  const dataColumn: GridColDef[] = [
    { field: "pk", headerName: "Pk", headerAlign: "center", align: "left", width: 140 },
    { field: "sk", headerName: "Sk", headerAlign: "center", align: "left", width: 120 },
    {
      field: "model_name",
      headerName: "Model Name",
      headerAlign: "center",
      align: "left",
      flex: 1,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <Box sx={{ minWidth: "100%" }}>
              <FormControl fullWidth size="small">
                <InputLabel id="user-type-select-label">Model Name</InputLabel>
                <Select
                  labelId="user-type-select-label"
                  id="user-type-select-label"
                  value={param.row.model_name}
                  label="Model Name"
                  onChange={(event: SelectChangeEvent) => {
                    param.row.model_name = event.target.value as string;
                    let tmpRow: any = [...dataRows];
                    let updated: boolean = false;
                    for (let i = 0; i < tmpRow.length; i++) {
                      if (tmpRow[i].pk === param.row.pk && tmpRow[i].sk === param.row.sk) {
                        tmpRow[i].model_name = event.target.value as string;
                        updated = true;
                      }
                    }

                    // 만일 변경된 행이 새로 추가된 행이면...
                    if (!updated) tmpRow[tmpRow.length - 1].model_name = event.target.value as string;
                    setDataRows(tmpRow);
                  }}
                >
                  {models.map((model: any) => (
                    <MenuItem key={model.request_id} value={model.request_id}>
                      {model.aatag + " → " + model.request_id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </>
        );
      },
    },
    {
      field: "temp",
      headerName: "Del",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <Stack spacing={0} direction="row">
              <IconButton onClick={() => fncRemove(param.row.sk)}>
                <RemoveCircleOutlineIcon className="color-red" />
              </IconButton>
            </Stack>
          </>
        );
      },
    },
  ];

  // 조회
  const fncGetList = useCallback(async () => {
    const param: GetModelSelectList = {
      idx: searchModelIdx,
    };
    setLoading(true);
    const res = await mainApi.get_model_select_list(param);
    if ("" + res.code === "200") {
      setDataRows(res.response.model_select_list);
    } else {
      toastRef.current?.toast("조회 중 에러가 발생했습니다.", "error", 3000, { vertical: "top", horizontal: "center" });
      console.error("fncGetList ERROR : ", res.response.error_msg);
    }
    setLoading(false);
  }, [searchModelIdx]);

  const fncModelList = useCallback(async () => {
    setLoading(true);
    const res = await mainApi.get_model_list();
    if ("" + res.code === "200") {
      setModels(res.response.model_list);
    } else {
      toastRef.current?.toast("모델목록 조회 중 에러가 발생했습니다.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      console.error("fncModelList ERROR : ", res.response.error_msg);
    }
    setLoading(false);
  }, []);

  // 저장
  const fncSaveList = useCallback(async () => {
    if (!window.confirm("자료를 저장하시겠습니까?")) return;
    const param: SetModelSelectList = {
      model_select_list: dataRows,
    };
    setLoading(true);
    const res = await mainApi.set_model_select_list(param);
    if ("" + res.code === "200") {
      toastRef.current?.toast("저장을 완료했습니다.", "success", 3000, { vertical: "top", horizontal: "center" });
      fncGetList();
    } else {
      toastRef.current?.toast("저장 중 에러가 발생했습니다.", "error", 3000, { vertical: "top", horizontal: "center" });
      console.error("fncSaveList ERROR : ", res.response.error_msg);
    }
    setLoading(false);
  }, [dataRows, fncGetList]);

  // 신규 행 추가
  const fncAddList = async () => {
    if (modelIdx === "" || modelType === "" || modelName === "") {
      toastRef.current?.toast("데이터(Index, Type, Model Name)를 입력하세요", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    let isDup: boolean = false;
    for (let i = 0; i < dataRows.length; i++) {
      if (dataRows[i].sk === `${modelIdx}#${modelType}`) isDup = true;
    }

    if (isDup) {
      toastRef.current?.toast("Index와 Type이 일치하는 건이 이미 있습니다.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    let tmpRow: any = [...dataRows];
    tmpRow.push({
      pk: "MODEL_SELECT",
      sk: `${modelIdx}#${modelType}`,
      model_name: modelName,
    });
    setDataRows(tmpRow);
    setTimeout(() => {
      gridApi.current.scrollToIndexes({ rowIndex: dataRows.length, colIndex: 1 }); // 마지막 행으로 스크롤 이동
      setSelectedRow([`MODEL_SELECT#${modelIdx}#${modelType}`]); // 현재 선택된 행 지정
    }, 100);
  };

  // 행 삭제
  const fncRemove = (sk: string) => {
    let tmpRow: any = [...dataRows];

    for (let i = 0; i < tmpRow.length; i++) {
      if (tmpRow[i].sk === sk) {
        tmpRow.splice(i, 1);
        i--;
      }
    }

    setDataRows([]);
    setDataRows(tmpRow);

    setTimeout(() => {
      setSelectedRow([]); // 변경된 행을 선택된 행으로
    }, 100);
  };

  // event Row Edit end
  const evtEditEnd = async (newRow: GridRowModel) => {
    let tmpRow: any = [...dataRows];
    let updated: boolean = false;
    for (let i = 0; i < tmpRow.length; i++) {
      if (tmpRow[i].pk === newRow.pk && tmpRow[i].sk === newRow.sk) {
        tmpRow[i] = newRow;
        updated = true;
      }
    }

    // 만일 변경된 행이 새로 추가된 행이면...
    if (!updated) tmpRow[tmpRow.length - 1] = newRow;
    setDataRows(tmpRow);
    return newRow;
  };

  // event Error when editting row
  const handleProcessRowUpdateError = useCallback((error: Error) => {
    console.error("Row Update Error : ", error.message);
  }, []);

  // event when model select combo
  const handleModelNameChange = useCallback((event: SelectChangeEvent) => {
    setModelName(event.target.value as string);
  }, []);

  useEffect(() => {
    fncModelList();
    fncGetList();
  }, [fncModelList, fncGetList]);

  // 로그인 체크
  const [view, setView] = useState(false);
  useEffect(() => {
    if (!props.userState.id) {
      window.location.href = "/login";
    }
    if (props.userState.userType !== "1") {
      window.location.href = "/";
    } else {
      setView(true);
    }
  }, [props.userState]);

  const [passwordConfirm, setPasswordConfirm] = useState(false);
  const pwdCallback = (result: boolean) => {
    setPasswordConfirm(result);
  };

  return (
    <div>
      {!passwordConfirm ? (
        <AskPassword callBack={pwdCallback} />
      ) : (
        <Grid container spacing={1} sx={{ paddingX: 1 }} component="div">
          {/* Title */}
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              모델설정관리
            </Typography>
          </Grid>
          {/* Title */}
          {/* Search */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }} elevation={5}>
              <Stack spacing={1} direction="column">
                <Stack spacing={1} direction="row">
                  <Grid item xs={8}>
                    <Stack spacing={1} direction="row">
                      <TextField
                        id="txtSearchModelIdx"
                        label="Index"
                        variant="outlined"
                        size="small"
                        value={searchModelIdx}
                        type="search"
                        autoComplete="off"
                        inputProps={{ enterKeyHint: "Enter" }}
                        onChange={(e) => {
                          setSearchModelIdx(e.target.value);
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") fncGetList();
                        }}
                        fullWidth
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={2} direction="row" justifyContent="flex-end">
                      <Button variant="contained" onClick={fncGetList}>
                        검색
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setOpenAddForm(true);
                        }}
                      >
                        추가
                      </Button>
                      <Button variant="contained" onClick={fncSaveList}>
                        저장
                      </Button>
                    </Stack>
                  </Grid>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
          {/* Search */}
          {/* Add form */}
          {openAddForm ? (
            <Grid item xs={12}>
              <Paper sx={{ p: 2 }} elevation={5}>
                <Stack spacing={1} direction="column">
                  <Stack spacing={1} direction="row">
                    <Grid item xs={8}>
                      <Stack spacing={1} direction="row">
                        <TextField
                          id="txtModelIdx"
                          label="Index"
                          variant="outlined"
                          size="small"
                          value={modelIdx}
                          type="search"
                          autoComplete="off"
                          inputProps={{ enterKeyHint: "Enter" }}
                          onChange={(e) => {
                            setModelIdx(e.target.value);
                          }}
                          sx={{ minWidth: "100px" }}
                        />
                        <TextField
                          id="txtModelType"
                          label="Type"
                          variant="outlined"
                          size="small"
                          value={modelType}
                          type="search"
                          autoComplete="off"
                          inputProps={{ enterKeyHint: "Enter" }}
                          onChange={(e) => {
                            setModelType(e.target.value);
                          }}
                          sx={{ minWidth: "200px" }}
                        />
                        <Box sx={{ minWidth: 300 }}>
                          <FormControl fullWidth size="small">
                            <InputLabel id="user-type-select-label">Model Name</InputLabel>
                            <Select
                              labelId="user-type-select-label"
                              id="user-type-select-label"
                              value={modelName}
                              label="Model Name"
                              onChange={handleModelNameChange}
                            >
                              {models.map((model: any) => (
                                <MenuItem key={model.request_id} value={model.request_id}>
                                  {model.aatag + " → " + model.request_id}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack spacing={2} direction="row" justifyContent="flex-end">
                        <Button variant="contained" onClick={fncAddList}>
                          적용
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setOpenAddForm(false);
                          }}
                        >
                          닫기
                        </Button>
                      </Stack>
                    </Grid>
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
          ) : (
            <></>
          )}
          {/* Add form */}
          {/* Main Grid */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }} elevation={5}>
              <div className={openAddForm ? "datatable-model-select-addform" : "datatable-model-select"}>
                <DataGrid
                  apiRef={gridApi}
                  rows={dataRows}
                  columns={dataColumn}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 100,
                      },
                    },
                    sorting: {
                      sortModel: [
                        {
                          field: "range_num",
                          sort: "asc",
                        },
                      ],
                    },
                  }}
                  pageSizeOptions={[3, 10, 20, 50, 100]}
                  getRowId={(row) => row.pk + "#" + row.sk}
                  processRowUpdate={evtEditEnd}
                  onProcessRowUpdateError={handleProcessRowUpdateError}
                  rowSelectionModel={selectedRow}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRow(newRowSelectionModel);
                  }}
                />
              </div>
            </Paper>
          </Grid>
          {/* Main Grid */}
        </Grid>
      )}
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </div>
  );
};

export default ModelSelect;
