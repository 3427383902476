import { useEffect, useState, useRef } from "react";
import dayjs, { Dayjs } from "dayjs";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { userState } from "../../../interface/MainInterface";
import {
  HttpAdminMainApi,
  GetDailyMakeContentListParam,
} from "../../../interface/admin-main-api";
import { CommonUtils } from "../../../utils/common_utils";
import DailyMakeContentChart from "./DailyMakeContentChart";
import Toast from "../../../utils/Toast";
import LoadingCircle from "../../../utils/LoadingCircle";
import "./DailyMakeContent.css";

interface propsType {
  userState: userState;
}

interface IGridCols {
  STD_DATE: string;
  TITLE_CNT: number;
  CONTENT_CNT: number;
}

const DailyMakeContent = (props: propsType) => {
  const mainApi = new HttpAdminMainApi();
  const cUtils = new CommonUtils();

  const [isLoading, setIsLoading] = useState(false);
  const toastRef: any = useRef();

  const [srchDateFrom, setSrchDateFrom] = useState<Dayjs | null>(
    dayjs().startOf("month")
  ); // 이번달 시작일
  const [srchDateTo, setSrchDateTo] = useState<Dayjs | null>(dayjs()); // 오늘날짜

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(100);

  const handleSrchDateFromChange = (newValue: Dayjs | null) => {
    setSrchDateFrom(newValue);
  };
  const handleSrchDateToChange = (newValue: Dayjs | null) => {
    setSrchDateTo(newValue);
  };

  const handlePaginationModelChange = (pageData: any) => {
    setPage(pageData.page);
    setPageSize(pageData.pageSize);
  };

  const [dataRows, setDataRows] = useState<IGridCols[]>([]);
  const dataColumn: GridColDef[] = [
    {
      field: "STD_DATE",
      headerName: "일자",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "TITLE_CNT",
      type: "number",
      headerName: "제목건수",
      headerAlign: "center",
      align: "right",
      width: 120,
    },
    {
      field: "CONTENT_CNT",
      type: "number",
      headerName: "문장건수",
      headerAlign: "center",
      align: "right",
      width: 120,
    },
  ];

  const fncSearch = async () => {
    setIsLoading(true);
    const param: GetDailyMakeContentListParam = {
      search_date_from: dayjs(srchDateFrom).format("YYYYMMDD"),
      search_date_to: dayjs(srchDateTo).add(1, "d").format("YYYYMMDD"),
      user_state: props.userState,
    };

    const res = await mainApi.get_daily_make_content_list(param);
    if ("" + res.code === "200") {
      setDataRows(res.response.result_list);
    } else {
      toastRef.current?.toast(
        "에러발생 : " + res.response.error_msg,
        "error",
        3000,
        {
          vertical: "top",
          horizontal: "center",
        }
      );
    }
    setIsLoading(false);
  };

  const fncDownloadExcel = () => {
    if (dataRows.length === 0) {
      toastRef.current?.toast(
        "다운로드할 데이터가 없습니다. 검색 후 이용하세요.",
        "error",
        3000,
        {
          vertical: "top",
          horizontal: "center",
        }
      );
      return;
    }
    // sheet header
    let sheetRow: any = [["일자", "제목건수", "문장건수"]];
    // 내용 생성
    for (const data of dataRows) {
      const detail = [data.STD_DATE, data.TITLE_CNT, data.CONTENT_CNT];
      sheetRow = [...sheetRow, detail];
    }
    // 컬럼 넓이
    const colWidth = [{ wpx: 150 }, { wpx: 100 }, { wpx: 100 }];
    const sheetName = "일별컨텐츠생성현황";
    // 파일명
    const fileName =
      "[" +
      dayjs(srchDateFrom).format("YYYY-MM-DD") +
      "~" +
      dayjs(srchDateTo).format("YYYY-MM-DD") +
      "] 일별 컨텐츠 생성현황" +
      ".xlsx";
    cUtils.downloadExcel(sheetRow, colWidth, sheetName, fileName);
  };

  useEffect(() => {
    fncSearch();
  }, []);

  return (
    <>
      <Grid container spacing={2} sx={{ paddingX: 1 }} component="div">
        {/* 네비게이션 */}
        {/* 검색조건영역 */}
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper sx={{ p: 2 }} elevation={5}>
              <Stack spacing={2} direction="row">
                <Grid item xs={8}>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <DesktopDatePicker
                      label="조회시작일"
                      format="MM/DD/YYYY"
                      value={srchDateFrom}
                      onChange={handleSrchDateFromChange}
                      slotProps={{ textField: { variant: "outlined" } }}
                    />
                    <DesktopDatePicker
                      label="조회종료일"
                      format="MM/DD/YYYY"
                      value={srchDateTo}
                      onChange={handleSrchDateToChange}
                      slotProps={{ textField: { variant: "outlined" } }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack spacing={2} direction="row" justifyContent="flex-end">
                    <Button variant="contained" onClick={fncSearch}>
                      검색
                    </Button>
                  </Stack>
                </Grid>
              </Stack>
            </Paper>
          </LocalizationProvider>
        </Grid>
        {/* 검색조건영역 */}
        {/* 그리드영역 */}
        <Grid item xs={12} sm={3}>
          <Paper sx={{ p: 2 }} elevation={5}>
            <Stack spacing={2} direction="column">
              <div className="datatable-daily-make-content">
                <DataGrid
                  rows={dataRows}
                  columns={dataColumn}
                  paginationModel={{
                    page,
                    pageSize,
                  }}
                  onPaginationModelChange={(pageData) =>
                    handlePaginationModelChange(pageData)
                  }
                  pageSizeOptions={[3, 10, 20, 50, 100]}
                  getRowId={(row) => row.STD_DATE}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                  loading={isLoading}
                />
              </div>
              <Button variant="contained" onClick={fncDownloadExcel} fullWidth>
                엑셀다운로드
              </Button>
            </Stack>
          </Paper>
        </Grid>
        {/* 그리드영역 */}
        {/* 차트영역 */}
        <Grid item xs={12} sm={9}>
          <Paper
            className="chart-daily-make-content"
            sx={{ p: 2 }}
            elevation={5}
          >
            <DailyMakeContentChart dataRows={dataRows} type="line" />
          </Paper>
        </Grid>
        {/* 차트영역 */}
      </Grid>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default DailyMakeContent;
