import { AES, enc } from "crypto-js";
import { appConfig } from "../../config/Config";
export function getQnaPageInfo() {
  const encObjString = window.localStorage.getItem(appConfig.qnaInfoSSName);

  if (!encObjString) return null;
  try {
    const bytes = AES.decrypt(encObjString, appConfig.seed);
    const decrypted = bytes.toString(enc.Utf8);
    let obj = JSON.parse(decrypted);
    return obj;
  } catch (error) {
    window.localStorage.removeItem(appConfig.qnaInfoSSName);
    return null;
  }
}

export function setQnaPageInfo(obj: any) {
  const objString = JSON.stringify(obj);
  const encObjString = AES.encrypt(objString, appConfig.seed).toString();
  window.localStorage.setItem(appConfig.qnaInfoSSName, encObjString);
}

export function clear() {
  window.localStorage.removeItem(appConfig.qnaInfoSSName);
  return true;
}
