import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import "./CopyWriter.css";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: "450px",
    minWidth: "450px",
    borderRadius: "8px",
    padding: "1rem 1rem",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.25)",
  },
}));

const TooltipProduct = () => {
  return (
    <>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit" className="copy-info-help">
              제품 또는 브랜드명을 넣어주세요.
            </Typography>
            <br />
            <Typography color="inherit" className="copy-info-help">
              ex1 : 나이키, 시스템 옴므, 골든듀, 스와로브스키
            </Typography>
            <Typography color="inherit" className="copy-info-help">
              ex2 : 웨딩 리빙 아이템 제안, 발렌다인데이 연인, 어린이날 선물
            </Typography>
          </React.Fragment>
        }
        placement="right"
      >
        <HelpOutlineIcon className="copy-info color-black" />
      </HtmlTooltip>
    </>
  );
};

export default TooltipProduct;
