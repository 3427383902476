import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { HttpMainApi, RePostQuestion } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import { appConfig } from "../../config/Config";
import "./QnaList.css";
import { HttpAdminMainApi } from "../../interface/admin-main-api";
import { Table, TableBody } from "@mui/material";
import QnAQuestionBox from "./qna-box/QnAQuestionBox";
import QnAAnswerBox from "./qna-box/QnAAnswerBox";

interface propsType {
  userState: userState;
}

interface detailType {
  content: string;
  check_answer: boolean;
  create_date: string;
}

interface titleType {
  title: string;
  answer: string;
  author: string;
  askTime: string;
}

const mainApi = new HttpMainApi();
const adminApi = new HttpAdminMainApi();

const QnaDetail = (props: propsType) => {
  let { state } = useLocation();
  let navigate = useNavigate();

  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [qInfo, setQinfo] = useState<any>();
  const [qnaList, setQnaList] = useState<any>([]);

  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [stateText, setStateText] = useState("답변예정");
  const [createTimestamp, setCreateTimestamp] = useState("");

  const [questionDetail, setQuestionDetail] = useState([]);
  const [answerDetail, setAnswerDetail] = useState([]);
  const [totalDetailList, setTotalDetailList] = useState<any>([]);
  const [checkReQuestion, setCheckRequestion] = useState(false);

  const titleItem: any = state.qInfo;
  const titleInfo: titleType = {
    title: titleItem.TITLE,
    answer: titleItem.ANSWER_YN,
    author: titleItem.AUTHOR,
    askTime: titleItem.CREATE_DATE
  }

  console.log("state : ", state);

  const fncGetQnaDetail = useCallback(async (_qInfo: any) => {
    // setIsLoading(true);
    const res = await adminApi.get_my_question_detail(_qInfo);
    if (res.code === "200") {
      console.log("res: ", res);
      setQnaList(res.response.qna_list);
      setTitle(res.response.question_detail_list[0].CONTENT);
      setAuthor(res.response.qna_list[0].CONTENT);
      setCreateTimestamp(res.response.qna_list[0].CREATE_DATE);
      setIsLoading(false);
      if (res.response.qna_list[0].ANSWER_YN === "Y") setStateText("답변완료");
      else setStateText("답변예정");
    } else {
      toastRef.current?.toast("조회 중 에러가 발생했습니다.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  }, []);

  const fncGoQnaList = useCallback(() => {
    navigate("/qna");
  }, [navigate]);

  const getQuestionDetail = async (qseq: string) => {
    const res = await adminApi.get_my_question_detail(qseq);
    if ("" + res.code === "200") {
      setQuestionDetail(res.response.question_detail_list);
    } else {
      alert("질문 불러오기에 실패하였습니다! 이전 페이지로 이동합니다");
      navigate("/qna/list/1");
    }
  };
  // 그 다음 답변들을 가져오기

  const getAnswerDetail = async (qseq: string) => {
    const res = await adminApi.get_my_answer_detail(qseq);
    if ("" + res.code === "200") {
      setAnswerDetail(res.response.answer_detail_list);
    } else {
      alert("답변 불러오기에 실패하였습니다! 이전 페이지로 이동합니다!");
      navigate("/qna/list/1");
    }
  };

  // 리팩토링 필요
  const combineDetailList = (q_detail: any[], a_detail: any[]) => {
    const convert_q_list = q_detail.map((item: any) => {
      // console.log("item : " + JSON.stringify(item));
      return {
        content: item.CONTENT,
        // check_answer 답변인지 질문인지 확인하는 용도
        check_answer: false,
        create_date: item.CREATE_DATE,
      };
    });
    const convert_a_list = a_detail.map((item: any) => {
      return {
        content: item.CONTENT,
        check_answer: true,
        create_date: item.CREATE_DATE,
      };
    });
    let tempTotalDetailList: any[] = [...convert_q_list, ...convert_a_list];
    tempTotalDetailList.sort((a: any, b: any) => {
      if (a.create_date > b.create_date) {
        return 1;
      } else if (a.create_date === b.create_date) {
        return 0;
      } else {
        return -1;
      }
    });
    // console.log(tempTotalDetailList);
    setTotalDetailList([...tempTotalDetailList]);
  };

  const qnaDetailList = (totalDetailList: detailType[]) => {
    const detailRenderingResult: any[] = totalDetailList.map(
      (item: any, index: number) => {
        if (item.check_answer) {
          return (
            <QnAAnswerBox
              key={index}
              answer={item.content}
              date={item.create_date.split("/")[0]}
            />
          );
        } else {
          return <QnAQuestionBox key={index} question={item.content} />;
        }
      }
    );
    return detailRenderingResult;
  };

  useEffect(() => {
    if (!props.userState.id) {
      window.location.href = "/login";
    }
    getQuestionDetail(titleItem.QSEQ);
    getAnswerDetail(titleItem.QSEQ);
  }, []);

  useEffect(() => {
    combineDetailList(questionDetail, answerDetail);
    // 모두 조회 완료되면 추가 문의 등록 버튼 활성화
    setIsLoading(true);
  }, [questionDetail, answerDetail]);

  // 마지막 원소가 답변인지 확인하는 코드 -> 리팩토링 필요
  useEffect(() => {
    if (totalDetailList.length > 0) {
      const lastData: detailType = totalDetailList[totalDetailList.length - 1];
      if (lastData.check_answer) {
        setCheckRequestion(true);
      } else {
        setCheckRequestion(false);
      }
    }
  }, [totalDetailList]);

  useEffect(() => {
    if (qInfo) {
      fncGetQnaDetail(qInfo); // 한번만 실행
    }
  }, [qInfo, fncGetQnaDetail]);

  useEffect(() => {
    setQinfo(state.qInfo); // 한번만 실행해야해서 별도 state로 관리한다.
  }, [state]);

  const [addQuestion, setAddQuestion] = useState("");

  const fncAddQuestion = useCallback(async () => {
    if (addQuestion === "" || addQuestion.trim() === "") {
      toastRef.current?.toast("추가 문의할 내용을 입력하세요.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    const param: RePostQuestion = {
      originQseq: qInfo.QSEQ,
      title: "추가문의",
      content: addQuestion,
      userState: props.userState,
    };

    setIsLoading(true);

    const res = await mainApi.repost_question(param);
    if ("" + res.code === "200") {
      toastRef.current?.toast("추가문의 등록을 완료했습니다.", "success", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      fncGetQnaDetail(qInfo);
    } else {
      toastRef.current?.toast("저장 중 에러가 발생했습니다.", "error", 3000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  }, []);

  return (
    <>
      <Box sx={{ p: 0.5 }}>
        <Stack direction="column" spacing={2}>
          <Box component={"div"} sx={{ marginTop: "0.7rem" }}></Box>
          <Box component={"div"}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                  <CardMedia
                    component="img"
                    image={"/images/icon-other-qna.png"}
                    sx={{
                      width: "31px",
                      height: "38px",
                      objectFit: "contain",
                      filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.15))",
                    }}
                  />
                  <Typography sx={{ display: "inline", fontWeight: "bold", fontSize: "1.5rem" }} component="span">
                    무엇을 도와드릴까요?
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Paper sx={{ p: 2, borderRadius: "1rem" }} elevation={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={1} sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  sx={{ display: "inline", fontWeight: "bold", fontSize: "1rem", color: "#6d89ed" }}
                  component="span"
                >
                  {stateText}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Typography sx={{ display: "inline", fontWeight: "bold", fontSize: "1rem" }} component="span">
                  {titleInfo.title}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={1} sx={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{ display: "inline", fontWeight: "bold", fontSize: "1rem" }} component="span">
                  {titleInfo.author}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} sx={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{ display: "inline", fontWeight: "bold", fontSize: "1rem" }} component="span">
                  {titleInfo.askTime}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Paper sx={{ p: 2, borderRadius: "1rem" }} elevation={3}>
            <Table>
              <TableBody>
                {totalDetailList.length > 0 ? (
                  qnaDetailList(totalDetailList)
                ) : (
                  <tr>
                    <td colSpan={4}>no data</td>
                  </tr>
                )}
                {/* 답변이 완료된 상태여야만 활성화 되어야 함 */}
                {checkReQuestion ? (
                  <></>
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: "center" }}>
                      <div
                        className="quest"
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        <span
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          답변이 완료될 때까지 잠시 기다려주세요.
                        </span>
                      </div>
                    </td>
                  </tr>
                )}
              </TableBody>
            </Table>
            {/* {qnaList.map((qnaInfo: any, index: number) => (
              <div key={qnaInfo.QSEQ}>
                <Box className="question-root">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={10} sx={{ display: "flex", justifyContent: "left" }}>
                      <Stack direction={"row"} spacing={2}>
                        <Typography className="question-icon">Q</Typography>
                        <Typography className="question-contents">{qnaInfo.CONTENT}</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={2} sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography className="answer-contents">{qnaInfo.CREATE_TIMESTAMP}</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                {index === qnaList.length - 1 && (qnaInfo.A_CONTENT === "" || qnaInfo.A_CONTENT === null) ? (
                  <Box className="answer-waiting">
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                      <Typography className="answer-contents font-bold">
                        답변 예정입니다. 잠시만 기다려주세요...
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Box className="answer-root">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={10} sx={{ display: "flex", justifyContent: "left" }}>
                          <Stack direction={"row"} spacing={2}>
                            <Typography className="answer-icon">A</Typography>
                            <Typography className="answer-contents">
                              안녕하세요 AI 카피라이터 {appConfig.systemName}입니다.
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={2} sx={{ display: "flex", justifyContent: "center" }}>
                          <Typography className="answer-contents">{qnaInfo.A_CREATE_TIMESTAMP}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sx={{ display: "flex", justifyContent: "left", pl: "1.8rem" }}>
                        <Typography className="answer-contents">{qnaInfo.A_CONTENT}</Typography>
                      </Grid>
                    </Box>
                    <Divider />
                    {index === qnaList.length - 1 && qInfo.ANSWER_YN === "Y" && (
                      <Box sx={{ mt: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                            <TextField
                              label="추가문의"
                              multiline
                              rows={3}
                              fullWidth
                              value={addQuestion}
                              autoComplete="off"
                              inputProps={{ enterKeyHint: "Enter" }}
                              onChange={(e) => {
                                setAddQuestion(e.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                fncAddQuestion();
                              }}
                            >
                              추가문의등록
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </>
                )}
              </div>
            ))} */}
          </Paper>
          <Box component={"div"}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    fncGoQnaList();
                  }}
                >
                  목록
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Stack>
        <LoadingCircle loading={isLoading} />
        <Toast ref={toastRef} />
      </Box>
    </>
  );
};

export default QnaDetail;
