import { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";

interface propsType {
  dataRows: any;
  type: string;
}

interface IGridCols {
  STD_DATE: string;
  TITLE_CNT: number;
  CONTENT_CNT: number;
}
Chart.register(CategoryScale);

const DailyMakeContentChart = (props: propsType) => {
  const [chartData, setChartData] = useState({
    labels: props.dataRows.map((data: IGridCols) =>
      data.STD_DATE.substring(5, 10)
    ),
    datasets: [
      {
        label: "제목건수",
        data: props.dataRows.map((data: IGridCols) => data.TITLE_CNT),
      },
      {
        label: "문장건수",
        data: props.dataRows.map((data: IGridCols) => data.CONTENT_CNT),
      },
    ],
  });

  useEffect(() => {
    if (props.dataRows.length > 0) {
      let newDataRows = [...props.dataRows];
      setChartData({
        labels: newDataRows
          .reverse()
          .map((data: IGridCols) => data.STD_DATE.substring(5, 10)),
        datasets: [
          {
            label: "제목건수",
            data: newDataRows.map((data: IGridCols) => data.TITLE_CNT),
          },
          {
            label: "문장건수",
            data: newDataRows.map((data: IGridCols) => data.CONTENT_CNT),
          },
        ],
      });
    }
  }, [props.dataRows]);

  return (
    <div className="chart-root" style={{ height: "100%" }}>
      {props.type === "bar" ? (
        <Bar
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: "일별 컨텐츠 생성현황",
              },
              legend: {
                display: true,
                position: "top",
              },
            },
            interaction: {
              intersect: false,
            },
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: "일자",
                },
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: "건수",
                },
              },
            },
          }}
        />
      ) : (
        <Line
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: "일별 컨텐츠 생성현황",
              },
              legend: {
                display: true,
                position: "top",
              },
            },
            interaction: {
              intersect: false,
            },
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: "일자",
                },
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: "건수",
                },
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default DailyMakeContentChart;
