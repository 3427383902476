import dayjs from "dayjs";
import * as XLSX from "xlsx";

interface Document extends HTMLDocument {
  fullscreenElement: any;
  mozFullScreenElement?: any;
  msFullscreenElement?: any;
  webkitFullscreenElement?: any;
}

export class CommonUtils {
  public getAddDateList(fromDays: number, addDays: number) {
    const dayText: string[] = ["일", "월", "화", "수", "목", "금", "토"];

    const currentDay = dayjs();
    let arrCalDates: any[] = [];
    for (let i = fromDays; i > 0; i--) {
      const result: string = currentDay.add(i * -1, "d").format("YYYYMMDD");
      let result_day: string = dayText[parseInt(currentDay.add(i * -1, "d").format("d"))];
      const result_date: string = currentDay.add(i * -1, "d").format("D");
      arrCalDates.push({
        broad_date: result,
        broad_day: result_day,
        day_text: result_date,
      });
    }

    for (let i = 0; i < addDays; i++) {
      const result: string = currentDay.add(i, "d").format("YYYYMMDD");
      let result_day: string = dayText[parseInt(currentDay.add(i, "d").format("d"))];
      const result_date: string = currentDay.add(i, "d").format("D");
      if (i === 0) {
        result_day = "오늘";
      }
      arrCalDates.push({
        broad_date: result,
        broad_day: result_day,
        day_text: result_date,
      });
    }

    return arrCalDates;
  }

  public dateFormat = (value: string, iPattern: string, oPattern: string) => {
    return dayjs(value, iPattern).format(oPattern);
  };

  public convertSecToTime = (timeInSeconds: number) => {
    const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);
    return {
      hours: result.substr(0, 2),
      minutes: result.substr(3, 2),
      seconds: result.substr(6, 2),
    };
  };

  public nl2br(obj: string) {
    return obj.replace(new RegExp("\r?\n", "g"), "<br />");
  }

  public isEmptyObj(obj: any) {
    if (obj.constructor === Object && Object.keys(obj).length === 0) {
      return true;
    }

    return false;
  }

  public null2EmptyString(obj: any) {
    if (obj === null) return "";
    else return obj;
  }

  public numericComma = (value: any) => {
    if (typeof value === "number") {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return undefined;
  };

  public copyToClipboard = (text: string) => {
    try {
      var textField = document.createElement("textarea");
      textField.innerText = text;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  // 최대공약수
  public gcd = (a: number, b: number) => {
    let gcb: number = 0;
    let num = [a, b];
    for (let i = 1; i <= Math.min.apply(null, num); i++) {
      if (Math.min.apply(null, num) % i === 0 && Math.max.apply(null, num) % i === 0) {
        gcb = i;
      }
    }
    return gcb;
  };

  public screenRatio = (a: number, b: number) => {
    const gcb = this.gcd(a, b);
    return [a / gcb, b / gcb];
  };

  // 전체화면 토글
  public toggelFullScreen = () => {
    if (this.isFullscreen()) {
      const doc: any = document;
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        /* Firefox */
        doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        doc.webkitExitFullscreen();
      } else if (doc.msExitFullscreen) {
        /* IE/Edge */
        doc.msExitFullscreen();
      }
    } else {
      let fullscreenElement: any = document.documentElement;
      if (fullscreenElement.requestFullscreen) {
        fullscreenElement.requestFullscreen();
      } else if (fullscreenElement.mozRequestFullScreen) {
        /* Firefox */
        fullscreenElement.mozRequestFullScreen();
      } else if (fullscreenElement.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        fullscreenElement.webkitRequestFullscreen();
      } else if (fullscreenElement.msRequestFullscreen) {
        /* IE/Edge */
        fullscreenElement.msRequestFullscreen();
      }
    }
  };

  // 전체화면 지원여부
  public isSupportFullScreen = () => {
    let fullscreenElement: any = document.documentElement;
    if (
      fullscreenElement.requestFullscreen ||
      fullscreenElement.mozRequestFullScreen ||
      fullscreenElement.webkitRequestFullscreen ||
      fullscreenElement.msRequestFullscreen
    ) {
      return true;
    } else {
      return false;
    }
  };

  //현재 전체화면 인지
  public isFullscreen = () => {
    if (
      (document as Document).fullscreenElement ||
      (document as Document).webkitFullscreenElement ||
      (document as Document).mozFullScreenElement ||
      (document as Document).msFullscreenElement
    ) {
      return true;
    } else {
      return false;
    }
  };

  //
  public convertHtml = (text: string) => {
    return text
      .replace("&amp;", "&")
      .replace("&lt;", "<")
      .replace("&gt;", ">")
      .replace("&quot;", '"')
      .replace("&apos;", "'");
  };

  public downloadExcel = (sheetContent: any, colWidth: any, sheetName: string, fileName: string) => {
    const sheet: any = XLSX.utils.aoa_to_sheet(sheetContent);
    sheet["!cols"] = colWidth;
    const chatFile = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(chatFile, sheet, sheetName);
    XLSX.writeFile(chatFile, fileName);
  };
}
