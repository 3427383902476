import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { appConfig } from "../../config/Config";

import "./Policy.css";

const Policy = () => {
  return (
    <>
      <Box sx={{ p: 0.5 }}>
        <Paper sx={{ p: 3, borderRadius: "1rem" }} elevation={3}>
          <Stack spacing={4} direction="column">
            <Box>
              <Typography variant="h6" className="policy-title" gutterBottom>
                제 1장 총칙
              </Typography>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                제 1조(목적)
              </Typography>
              <Typography variant="body1" gutterBottom>
                본 약관은 주식회사 현대아이티앤이(이하 “회사”)가 운영하는 {appConfig.systemName}(
                {appConfig.systemNameEng}) 및 이에 부수하는 제반 서비스(통칭 이하 “서비스”)를 이용함에 있어 서비스
                이용자의 이용 조건 및 절차, 양 당사자 간의 권리·의무 등을 규정하는데 그 목적이 있습니다.
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                제 2조(정의)
              </Typography>
              <Typography variant="body1" gutterBottom>
                본 약관에서 사용하는 주요 용어의 정의는 다음과 같습니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                1. 회원: 서비스를 제공받기 위하여 정해진 가입 절차에 따라 절차를 진행하여 정상적으로{" "}
                {appConfig.systemName} 서비스를 이용할 수 있는 권한을 부여 받은 개인 또는 단체를 말합니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                2. 회원이 {appConfig.systemName}({appConfig.systemNameEng})를 이용할 수 있는 인터넷 사이트(
                {appConfig.systemUrl})를 말합니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                3. 사용자 아이디(ID): 회원 식별과 서비스 이용을 위하여 회원이 정하고 웹사이트가 승인하는 문자와 숫자의
                조합입니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                4. 비밀번호(Password): 회원이 자신의 비밀 보호를 위하여 선정한 문자와 숫자의 조합입니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                5. 콘텐츠: 회사가 {appConfig.systemName}({appConfig.systemNameEng})를 통해 제공하는 기능으로 생성된
                문장 또는 문장 콘텐츠(문자, 부호 등)입니다.
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                제 3조(이용약관의 효력 및 변경)
              </Typography>
              <Typography variant="body1" gutterBottom>
                본 약관에서 사용하는 주요 용어의 정의는 다음과 같습니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                1. 본 약관의 내용은 회원이 알 수 있도록 웹사이트 화면에 게시하고, 그 내용이 웹사이트에 게시되거나 기타의
                방법으로 회원에게 공지함으로써 효력이 발생합니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                2. 본 약관은 관련 법을 위배하지 않는 범위 내에서 회사에 의해 개정 가능하며 약관을 개정하고자 할 경우에는
                약관이 개정된다는 사실과 개정된 내용 등을 적용일자 7일 전까지 공지합니다. 다만, 회원에게 불리하게
                약관내용이 변경되는 경우에는 최소 30일 이상의 사전 유예기간을 두고 고지합니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                3. 변경된 약관의 효력 발생일 이후에 회원이 {appConfig.systemName}({appConfig.systemNameEng})를 계속
                이용할 경우 변경된 이용약관의 모든 변경 사항에 동의한 것으로 간주됩니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                4. 이 약관에 작성 및 정하지 아니한 사항과 이 약관 해석에 전자상거래 등에서의 소비자보호에 관한 법률,
                약관의 규제에 관한 법률, 기타 관계법령 또는 상관례에 따릅니다.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" className="policy-title" gutterBottom>
                제 2장 서비스 이용 계약
              </Typography>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                제 4조 (서비스 이용 계약의 체결)
              </Typography>
              <Typography variant="body1" gutterBottom>
                1. 이용 계약은 이용 신청자가 회원가입 절차 중 약관의 내용에 동의하고 이에 대하여 회사가 승낙함으로써
                체결됩니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                2. 회사는 필요에 따라 회원에게 실명 확인 및 본인 인증 요청을 할 수 있으며, 그 절차와 방식은 관계 법령에
                따릅니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                3. 실명이나 실제 정보를 입력하지 않은 회원은 법적인 보호를 받을 수 없으며, 서비스 이용이 불가합니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                4. 회원은 회원가입시 기재한 사항이 변경되었을 경우, 온라인으로 수정을 하거나 전자우편, 기타 방법으로
                회사에 그 변경사항을 알려야 합니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                5. 제 4항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                제 5조 (서비스 이용 신청)
              </Typography>
              <Typography variant="body1" gutterBottom>
                1. 회원으로 웹사이트를 가입하여 본 서비스를 이용하고자 하는 자는 회사에서 요청하는 기본 정보(필수
                정보)를 기입하여야 합니다. 이후 회원이 된 자가 기입한 개인 정보는 개인정보보호방침에 따라 관리됩니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                2. 모든 회원은 반드시 회원 본인의 정보를 등록 및 제공하여야 합니다. 실명이나 실제 정보를 입력하지 않은
                사용자는 법적인 보호를 받을 수 없으며, 서비스 이용이 불가합니다. 타인의 정보를 도용하거나 허위 정보를
                등록하는 경우 관계 법령에 따라 처벌받을 수 있습니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                3. 회사는 필요에 따라 회원이 등록한 정보에 대하여 확인 조치를 취할 수 있습니다. 회원은 회사의 확인
                조치에 대하여 적극적으로 협력하여야 하며, 만일 이를 준수하지 않을 경우 회사는 회원이 등록한 정보를
                부정한 것으로 간주하여 처리할 수 있습니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                4. {appConfig.systemName}({appConfig.systemNameEng})은 1인 1계정을 원칙으로 하고 있으며, 하나의 회원
                계정으로 동시에 중복 로그인이 불가합니다.
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                제 6조 (서비스 이용의 제한)
              </Typography>
              <Typography variant="body1" gutterBottom>
                1. 회사는 아래 사항에 해당하는 경우에 회원의 서비스 이용을 제한하거나 이용 계약 승낙을 철회할 수
                있습니다.
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ① 회원 정보를 허위로 기재하여 신청한 경우
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ② 타인의 정보를 도용하여 신청한 경우
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ③ 법령 위반 또는 사회의 통념적 질서 또는 미풍 양속을 저해할 목적으로 신청한 경우
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ④ 부정한 용도로 서비스를 이용하는 경우
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ⑤ 기타 현행 법령에 위배되는 목적으로 서비스를 이용하는 경우
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ⑥ 이용약관 위반으로 회원 자격을 박탈당하고 1년 이내에 재가입 하는 경우
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ⑦ 기타 사이트 소정의 이용신청요건을 충족하지 못하거나 규정한 제반 사항을 위반하여 신청하는 경우
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ⑧ 회원이 본 약관 제10조의 의무를 다하지 않거나 회사의 서비스 운영 정책을 위반하는 경우
              </Typography>
              <Typography variant="body1" gutterBottom>
                2. 회사는 아래 사항에 해당하는 경우에 그 제한 사유가 해소될 때까지 이용 계약 체결을 유보할 수 있습니다.
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ① 서비스 관련 설비에 여유가 없는 경우
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ② 기술상 또는 업무상 지장이 있는 경우
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ③ 기타 회사의 사유로 필요하다고 인정되는 경우
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                제 7조 (서비스 이용을 위한 기술적 요구 사항)
              </Typography>
              <Typography variant="body1" gutterBottom>
                {appConfig.systemName}({appConfig.systemNameEng}) 서비스를 이용하기 위하여 아래의 필수 사양이
                충족되어야 합니다. 또한 사용자가 해당 사양에 충족되지 못한 사유를 문제로 서비스 사용에 이의를 제기할 시
                회사는 아무런 책임이 없음을 밝힙니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                필수 사양
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                - 웹브라우저: 크롬(Chrome) 또는 사파리(Safari)에 최적화되어 있습니다.
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                - 모바일: 미지원으로 웹브라우저에서 사용 가능합니다.
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                제 8조 (개인정보 보호)
              </Typography>
              <Typography variant="body1" gutterBottom>
                1. 회사는 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호할 의무가 있습니다. 개인정보의 보호 및
                사용에 대해서는 관련 법령 및 회사의 개인정보보호방침이 적용됩니다. 단, 회사의 서비스 이외의 연결된
                사이트에서는 회사의 개인정보보호방침이 적용되지 않습니다. 또한, 회원은 아이디(ID) 또는 비밀번호 등이
                타인에게 노출되지 않도록 철저히 관리해야 하며 회원의 귀책사유로 인해 노출된 정보에 대해서 회사는
                책임지지 않습니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                2. 회사는 다음 각 호의 경우 법이 허용하는 범위 내에서 회원의 개인정보를 제3자에게 제공할 수 있습니다.
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ① 수사기관이나 기타 정부기관으로부터 정보 제공을 요청 받은 경우
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ② 회원의 법령 또는 약관의 위반을 포함하여 부정행위 확인 등의 정보 보호 업무를 위해 필요한 경우
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ③ 기타 법률에 의해 요구되는 경우
              </Typography>
              <Typography variant="body1" gutterBottom>
                3. 회사는 필요시 {appConfig.systemName}({appConfig.systemNameEng})의 사용자 아이디(ID) 및 회원의
                개인정보를 회사 또는 회사가 운영하는 기타 서비스의 회원 개인정보와 관련하여 내부 방침에 따라 열람 및
                사용할 수 있습니다.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" className="policy-title" gutterBottom>
                제 3장 계약 당사자의 의무
              </Typography>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                제 9조 (회사의 의무)
              </Typography>
              <Typography variant="body1" gutterBottom>
                1. 회사는 법령과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 이 약관이 정하는 바에 따라
                지속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다해 노력합니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보보호를 위해 보안시스템을 갖추어야 하며
                개인정보처리방침을 공시하고 준수합니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                3. 회사는 회원으로부터 제기되는 의견이나 개선 요청이 정당하다고 인정될 경우 즉시 처리하는 것을 원칙으로
                합니다. 단, 즉시 처리가 곤란한 경우에는 사용자에게 그 사유와 처리 일정을 통보하여야 합니다.
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                제 10조 (회원의 의무)
              </Typography>
              <Typography variant="body1" gutterBottom>
                1. 회원은 회원가입 신청 또는 회원정보 변경 시 모든 사실에 근거하여 작성해야 합니다. 위반 시 서비스
                이용과 관련하여 일체의 권리를 주장할 수 없습니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                2. 회원은 이 약관 및 회사의 공지사항, 웹사이트 이용 안내 등을 숙지하고 준수해야 하며 다음 각 호에
                해당하는 행위를 해서는 안되며, 할 경우 회사는 서비스 이용 제한 및 적법한 조치를 포함하여 제재를 가할 수
                있습니다.
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ① 회원가입 신청 또는 회원정보 변경 시 허위 내용을 기재하는 행위
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ② 타인의 정보 또는 결제 수단을 도용하는 행위
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ③ 회사로부터 허가를 받지 않고 회사의 서버를 해킹하거나 회사의 웹사이트 또는 서비스, 게시된 정보 전체
                또는 일부분을 임의로 변경하거나, 회사의 서비스를 비정상적인 방법으로 사용하는 등의 행위
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ④ 본인이 아닌 제 3자에게 계정을 공유, 양도하는 행위
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ⑤ 정치활동, 선거운동 등의 용도로 서비스를 이용하는 행위
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ⑥ 기타 제3자의 저작권 등 지적재산권을 침해하거나 제 3자의 명예 손상 및 업무를 방해하는 행위
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ⑦ 회사의 서비스를 이용하여 얻은 정보를 무단으로 복제, 유통, 조장하거나 상업적으로 이용하는 행위,
                알려지거나 알려지지 않은 버그를 악용하여 서비스를 이용하는 행위
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ⑧ 외설 또는 폭력적인 메시지/화상/음성/기타 공서양속에 반하는 정보를 홈페이지에 공개 또는 게시하는 행위
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ⑨ 공공질서 및 미풍양속에 위반되거나 범죄적 행위에 결부되는 것으로 판단되는 행위
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ⑩ 기타 관계 법령에 위배되는 행위
              </Typography>
              <Typography variant="body1" gutterBottom>
                3. 회원은 관계법령, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항
                등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                4. 회원은 본인의 아이디, 비밀번호 등에 대한 관리 책임을 부담하며, 회원의 관리소홀로 인하여 발생한 문제에
                대하여 회사는 책임을 지지 않습니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                5. {appConfig.systemName}({appConfig.systemNameEng})를 통해 생성된 콘텐츠에 대한 사실 관계 및 법적,
                윤리적 문제 확인, 내용 검증 및 사용에 대한 모든 책임은 회원에게 있으며, 회사 및 회원은 제3자의 지적
                재산권을 침해해서는 안됩니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                6. 회원은 {appConfig.systemName}({appConfig.systemNameEng})를 악의적인 목적으로 이용할 수 없습니다.
                악의적인 목적이란 해당 서비스를 사용하는 과정에서 고의적인 값을 입력하여 차별적 의견 또는 부당한 가치
                판단 등 편향적인 결과값을 발생시키는 것이 대표적이며, 그 밖에도 회원이 악의적인 의도를 가지고 해당
                서비스를 사용하여 발생시키는 모든 문제를 포함합니다.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" className="policy-title" gutterBottom>
                제 4장 서비스의 제공 및 이용
              </Typography>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                제 11조 (서비스 이용)
              </Typography>
              <Typography variant="body1" gutterBottom>
                1. 서비스 이용은 회사의 업무 또는 기술 상의 특별한 지장이 없는 한 연중무휴, 1일 24시간 운영을 원칙으로
                합니다. 단, 회사의 업무상 또는 기술상의 이유로 서비스를 일시 중단 할 수 있습니다. 이러한 경우 해당
                내용에 대하여 {appConfig.systemName} 웹사이트 공지 또는 기타의 방법으로 사전 또는 사후에 이를
                공지합니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                2. 회원은 본 약관에 규정된 사항을 준수하여 서비스를 이용하며, 이 약관에 명시되지 않은 서비스 이용에 대한
                사항은 회사가 정해 웹사이트에 게시하거나 또는 별도로 공지하는 내용에 따릅니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                3. B2B 서비스를 활용하는 경우, 서비스 이용 약관과 회사간 계약서에 명시된 항목이 상이할 경우 계약서의
                조항을 따릅니다.
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                제 12조 (서비스 제공의 중지)
              </Typography>
              <Typography variant="body1" gutterBottom>
                1. 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부의 제공을 중지할 수 있습니다.
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ① 긴급한 시스템 점검, 증설, 교체, 고장 혹은 오작동을 일으키는 경우
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ② 국가 비상사태, 정전, 천재지변 등의 불가항력적인 사유가 있는 경우
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ③ 전기통신사업법에 상에 규정된 기간통신사업자/인터넷망사업자가 서비스를 중지했을 경우
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ④ 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
              </Typography>
              <Typography variant="body1" className="policy-subbody" gutterBottom>
                ⑤ 회사가 서비스 제공에 이용하는 네이버 운영 ‘CLOVA STUDIO’ 서버에 문제가 발생하는 경우를 포함하여 외부
                제휴업체 측 사유로 정상적인 서비스 제공이 어려운 경우
              </Typography>
              <Typography variant="body1" gutterBottom>
                2. 회사는 위 규정 이외에도 {appConfig.systemName}({appConfig.systemNameEng}) 정책 및 관련 규정,
                회사의 공식적인 결정에 의거하여 서비스의 이용을 제한하거나 정지할 수 있습니다. 회사의 결정에 의한 서비스
                제한 또는 정지는 사전에 공지하는 것을 원칙으로 하나, 부득이한 경우가 발생할 수 있습니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                3. 회사는 영업양도, 분할, 합병 등에 따른 영업의 폐지, 콘텐츠 제공의 계약만료, 당해 서비스의 현저한 수익
                악화 등 경영상의 중대한 사유로 인해 서비스를 지속하기 어려운 경우에는 서비스를 중단할 수 있습니다. 이
                경우 중단일자 30일 이전까지 중단일자 및 사유를 앱 초기화면 또는 그 연결화면, 회사 홈페이지 등 기타
                서비스 제공 화면을 통해 공지하고 회원에게 통지합니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                4. 회사는 제1항 각 호의 사유에 의하여 서비스가 중단된 경우 또는 서비스 중단에 대하여 회사가 고의 또는
                과실이 없음을 입증하는 경우에는 회원이 입은 손해에 대해 배상 책임을 지지 않습니다.
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                제 13조 (저작권 및 서비스 관리)
              </Typography>
              <Typography variant="body1" gutterBottom>
                1. 회원이 {appConfig.systemName}({appConfig.systemNameEng})를 활용해 만든 콘텐츠 및 편집 혹은 저장한
                ‘사용자 결과물’이 타인의 저작권에 위반되는 등의 문제가 발생했을 경우, 이에 대한 모든 책임은 회사에 있지
                않고 이를 외부에서 사용한 해당 회원에 있습니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                2. 회원이 {appConfig.systemName}({appConfig.systemNameEng})를 통해 생성된 결과값을 외부에 공개하는
                과정에서 회사에 피해를 입힌 경우, 그에 대한 법률상 책임을 부담합니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                3. 회원은 자신이 서비스 이용과정에서 입력하는 글, 기타 자료가 제 3자의 지적재산권 및 기타 권리를
                침해하지 않는다는 것을 보증합니다. 회원이 이를 위반하여 제 3자와 회원 또는 회사 사이에 소송, 이의제기,
                권리주장 기타 일체의 분쟁이 발생하는 경우, 회원은 회사를 면책하고 해당 분쟁을 해결하여야 하며 이로 인해
                회사가 손해를 입은 경우 해당 손해를 배상하여야 합니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                4. 회사는 서비스 품질 관리를 위하여 생성된 콘텐츠에 대하여 정기적인 사후 검토 작업을 진행합니다. 회사의
                정책 또는 법규를 위반한다고 합리적으로 판단되는 콘텐츠는 삭제하거나 게시를 거부할 수 있습니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                5. 회원의 입력값과 콘텐츠는 더 나은 서비스 제공을 위한 엔진 개선에 활용될 수 있으며 모델 평가에 사용될
                수 있습니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                6. {appConfig.systemName}({appConfig.systemNameEng})은 사용자에게 친근하고 유용한 AI를 지향하고
                있습니다. 그 과정에서 올바른 표현 도출과 개인정보 보호를 우선으로 하고 있으며, 잘못된 정보와 편항된
                정보가 노출되지 않도록 노력하고 있습니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                7. 본 규정을 준수하지 않은 회원은 별도의 공지 없이 이용이 정지되거나 제한될 수 있으며, 회사는 이에 대한
                책임을 부담하지 않습니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                8. 제 13 조는 회사가 서비스를 운영하는 동안 유효하며, 회원 탈퇴 후에도 지속적으로 적용됩니다.
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                제 14조 (정보의 제공)
              </Typography>
              <Typography variant="body1" gutterBottom>
                회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보에 대하여 서비스 내의 화면이나
                전자우편(E-mail) 등의 방법으로 회원에게 정보를 제공할 수 있습니다. 단, 광고성 정보에 해당하는 경우
                회원으로부터 수신 동의를 받습니다.
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                제 15조 (계약 사항의 변경 및 해지)
              </Typography>
              <Typography variant="body1" gutterBottom>
                회원은 웹사이트 내에서 서비스 이용 계약을 해지할 수 있으며, 회원이 이용 계약을 해지하고자 하는 때에는
                해당 회원 본인이 직접 웹사이트에 접속하여 가입해지(회원 탈퇴)를 진행해야 합니다. 회원의 계약 해지 후에는
                그 동안 생산한 콘텐츠 등 모든 기록이 다 삭제되어, 복구가 불가합니다. 또한, 해당일부터는 탈퇴 회원으로써
                서비스를 이용하실 수 없습니다. 회사는 가입 탈퇴 후 삭제된 작업물이 {appConfig.systemName}(
                {appConfig.systemNameEng})에서 제작되었다는 것을 입증하거나 책임 지지 않으며, 해지 완료되어 소실된
                사용자의 모든 콘텐츠 등에 대한 책임은 원칙적으로 해당 사용자에게 있습니다.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" className="policy-title" gutterBottom>
                제 5장 기타
              </Typography>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                제 16조 (분쟁 해결)
              </Typography>
              <Typography variant="body1" gutterBottom>
                1. 회사는 회원이 제기하는 정당한 의견이나 불만을 반영하고 회사가 납득할만한 피해 발생시 그 피해에 대한
                보상을 처리하기 위해 필요 시 피해 보상 처리 회의를 소집 • 운영합니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                2. 회사는 회원으로부터 제출되는 불만사항 및 의견을 우선으로 수용하고 내부 회의를 통해 관련 사항을
                처리합니다. 신속한 처리가 곤란한 경우 사용자에게 사유와 진행사항을 공지합니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                3. 회사와 회원 간에 발생한 전자상거래 분쟁과 관련하여 사용자의 피해구제신청이 있는 경우에는
                공정거래위원회 또는 시 • 도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                제 17조 (면책 및 손해배상)
              </Typography>
              <Typography variant="body1" gutterBottom>
                1. 회사 또는 회원은 본 약관을 위반하여 상대방에게 손해를 입힌 경우 그 손해를 배상할 책임이 있습니다.
                다만, 고의 또는 과실이 없는 경우 그러하지 아니 합니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                2. 회사는 천재지변, 불가항력 기타 당사의 합리적인 통제범위를 벗어난 사유로 인하여 인터넷상 서비스를
                제공할 수 없는 경우에는 그에 대한 책임을 부담하지 않습니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                3. 회사는 회원의 귀책사유로 인하여 서비스를 제공할 수 없는 경우에는 그에 대한 책임을 부담하지 않습니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                4. 회사는 회원이 서비스를 이용함으로써 기대되는 수익을 얻지 못하거나 서비스를 통해 얻은 자료를 이용하여
                발생한 손해에 대해서는 책임을 부담하지 않을 수 있습니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                5. 회원이 화면에 게재한 정보, 자료, 사실 등의 내용에 관한 신뢰도 또는 정확성에 대하여는 해당 회원이
                책임을 부담하며, 당사는 내용의 부정확 또는 허위로 인해 회원 또는 제3자에게 발생한 손해에 대하여는 아무런
                책임을 부담하지 않습니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                6. {appConfig.systemName}({appConfig.systemNameEng})은 하드웨어 및 소프트웨어적인 오류가 발생할 수
                있습니다. 회사는 최선을 다하여 오류의 발생을 막기 위하여 노력하겠으나, 오류 및 장애의 발생으로 인한
                사용자의 데이터의 유실, 물질적/정신적 손실, 비즈니스의 중단 등으로 인한 피해에 대하여 회사는 책임을 지지
                않습니다.
              </Typography>
              <Typography variant="body1" gutterBottom>
                7. 회사는 사전 통보 없이 웹사이트 내의 콘텐츠, 소프트웨어, 게시물 및 디자인 등을 변경할 수 있습니다.
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                제 18조 (약관 외 준칙)
              </Typography>
              <Typography variant="body1" gutterBottom>
                본 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 관계법령 및 상관례에 따릅니다.
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                제 19조 (분쟁조정 및 관할법원)
              </Typography>
              <Typography variant="body1" gutterBottom>
                본 약관에 의한 서비스 이용과 관련한 제반 분쟁 및 소송은 회원의 주소를 관할하는 법원에 의하고, 주소가
                없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가
                분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" className="policy-subtitle" gutterBottom>
                부칙
              </Typography>
              <Typography variant="body1" gutterBottom>
                본 서비스 이용약관은 2023년 5월 15일(월)부터 시행합니다.
              </Typography>
            </Box>
          </Stack>
        </Paper>
      </Box>
    </>
  );
};

export default Policy;
